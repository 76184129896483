import React, { useState, useRef } from "react";
import StudioHeader from "../elements/studioheader.js";
import Wallpaper from "../elements/wallpaper.js";
import StudioFunnelHeader from "../elements/studiofunnel/studiofunnelheader.js";
import { Link } from "react-router-dom";
import Eye from "../medias/eye.svg";
import EyeSlash from "../medias/eye-slash.svg";
import axios from "axios";
import X from "../medias/X.svg";
import V from "../medias/V.svg";
import LogoAbyss from "../medias/logoabyss.svg";
import { useEffect } from "react";
import Centaura from "../medias/centaura.png";

function SpecialOffer() {
  
  //STRIPE PRICE ID ///////////
  const [price_id_to_api, set_price_id_to_api] = useState("");
  const [price1, setPrice1] = useState("price_1OVtz7CdrshQWAUbB54u58Kb"); //Monthly
  const [price2, setPrice2] = useState("price_1OVtz7CdrshQWAUbn6HgHnPv"); //Quarterly
  const [price3, setPrice3] = useState("price_1OVtz7CdrshQWAUbfDWRoSSD"); //Yearly
  
  /////////////////////////////

  const [token,setToken] = useState(localStorage.getItem("token"));
  const [lien, setlien] = useState();
  const [link, setLink] = useState();
  const [email, setemail] = useState("");
  const [tkn, settkn] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [stripeOn, setstripeOn] = useState(true); //fonction hide show mdp
  const [visiblepage, setvisiblepage] = useState(1);
  const [weight, setweight] = useState("w-1/4");
  const btn = (
    <div>
      Get 2 months free
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="inline-block ml-2 mr-1 -translate-y-0.5"
        viewBox="0 0 16 16"
      >
        <path d="M7.462 0H0v7.19h7.462zM16 0H8.538v7.19H16zM7.462 8.211H0V16h7.462zm8.538 0H8.538V16H16z" />
      </svg><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="inline-block  mr-1 -translate-y-0.5" viewBox="0 0 16 16">
  <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
  <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
</svg> 
    </div>
  );
  const inputstat1 = useRef(null);
  const inputstat2 = useRef(null);
  const emailIsValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    email
  );

  const [errormsg, setErrormsg] = useState("");
  const [passwordisVisible, setPasswordisVisible] = useState(false);
  const [password, setPassword] = useState("");
  const passwordHasValidLength = password.length >= 8;
  const passwordHasNumber = /\d/.test(password);
  const passwordHasLetter = /[a-zA-Z]/.test(password);
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [username, setUsername] = useState(""); // fonction verify mdp
  const usernameValidLength = username.length >= 3; //taille du mdp
  const [validmail, setValidmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [newsletterOn, setNewsletterOn] = useState(true); //fonction hide show mdpé
  const error = useRef(null);
  const [validCode, setValidCode] = useState("");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoutez cette option pour un défilement fluide
    });
  };

  useEffect(() => {
    if (link) {
      window.location.href = link;
    }
  }, [link]);

  const Dropdown = useRef(null);
  const Arrow = useRef(null);
  const handleShow = () => {
    if (Dropdown.current) {
      Dropdown.current.classList.toggle("hidden");
      Arrow.current.classList.toggle("rotate-180");
    }
  };

  const Dropdown2 = useRef(null);
  const Arrow2 = useRef(null);
  const handleShow2 = () => {
    if (Dropdown2.current) {
      Dropdown2.current.classList.toggle("hidden");
      Arrow2.current.classList.toggle("rotate-180");
    }
  };

  const getStripe = async (stripeId) => {
    
    try {

      // Faire la requête POST avec axios
      const response = await axios.post("/api:o5YBY4Ce/subscription/session/specialoffer", {
        price_id : stripeId
      }, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token
        }
      });
      /*if (response.data == "Already a subscription") {
        alert("Already a subscription");
      }
      else */if (response.status == 200) {
        // Mettre à jour l'URL
        setLink(response.data.result_1.url);
      } else {
        // Mettre à jour la page et le poids en cas d'erreur
        alert("Error !");
        window.location.reload();
      }
    } catch (error) {
      // Gérer les erreurs et mettre à jour la page et le poids
      alert("Catch during stripe call");
      window.location.reload();
    }
  };
  


  //Quand un plan est selectionné : 
  const selectplan = async (stripeId) => {
    setToken(localStorage.getItem("token"));
    try {
      const response = await axios.get("/api:5FCR-wUS/user/getinfos", {
        headers: {
          Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: token
        },
      });

      if (response.status == 200) {
        getStripe(stripeId);
      } else {
        setvisiblepage(3);
        setweight("w-1/2")
      }
    } catch (error) {
      setvisiblepage(3);
      setweight("w-1/2")
    }
  };











  const registerUser = async () => {
    try {
      const response = await axios.post("/api:sVHbJHW3/special_offer/signup", {
        name: username,
        email: validmail,
        password: password,
        ambassador_code: validCode,
      });
  
      if (response.status == 200) {
        sendNewsletter();
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            localStorage.removeItem("token");
            localStorage.setItem("token", response.data.token);
            setToken(response.data.token);
            setweight("w-3/4");
            setvisiblepage(1);
           
      } else {
        error.current.classList.remove("hidden");
        setErrormsg("");
      }
    } catch (error) {
      setErrormsg("An error occurred", error);
      alert(error);
    }
  };



  

  const sendNewsletter = () => {
    if (newsletterOn) {
    
    axios
      .post("/api:swpGnftB/newsletter", {
        email: validmail,
      })
      .then(
        (response) => {
          if (response.data.success) {
            console.log("Success newsletter")
          }
        },
        (error) => {
            console.log("error newsletter")
        }
      );

    }
  };

  const LogInVerif = async () => {
    try {
      const response = await axios.post("/api:sVHbJHW3/special_offer/login", {
        email: email,
        password: password,
        ambassador_code: validCode,
      });
  
      if (response.status == 200) {
        localStorage.removeItem("token");
        localStorage.setItem("token", response.data.token);
        setToken(response.data.token);
        setweight("w-3/4");
        setvisiblepage(1);
  
      } else {
        alert("error");
      }
    } catch (error) {
      alert("Catch");
    }
  };






  const Popup = ({ onClose }) => {
    return (
      <div className="fixed top-0 z-40 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 ">
        <div className="bg-gray-900 border border-gray-800 p-8 mx-6 rounded-2xl fixed max-w-5xl my-auto opacity-95 ">
          <p className="title-center"> Studio by abyss is not for now available on Mac os :/</p>
          <p className="text-center my-6 w-full">Coming later!</p>
          <div className="w-32 mx-auto">
          <button className="btn-indigo" onClick={onClose}>
            OK
          </button></div>
          
        </div>
      </div>
    );
  };


  const [isMac, setIsMac] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Logique pour déterminer si l'utilisateur est sur un Mac
    const userAgent = window.navigator.userAgent.toLowerCase();
    setIsMac(userAgent.includes('massdsdsdsdsdc'));

    // Afficher la popup si l'utilisateur est sur un Mac

  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  return (
    <div>
      {showPopup && <Popup onClose={handleClosePopup} />}
      <div>
        <StudioFunnelHeader />
        <StudioHeader weight={weight} />
      </div>
      <div>
        <div className={visiblepage == 1 ? "block" : "hidden"}>
          <Wallpaper />
          <h1 className="title-center mt-12">Get 2 months free.</h1>
          <div className="pt-2 pb-5">
            <p className="txt-center-g">
             
              <b> No payment will be incurred until 2 months. </b>
            </p>
          </div>
          <div className="mt-3 max-w-[1400px] mx-auto">
            <div className="w-full desktop:w-1/4 float-left p-2">
              <div className="p-2 border-2 border-gray-800 mb-2 desktop:mb-6 pb-52 bg-gray-900 rounded-2xl pt-6 mt-3">
                <div className="py-6 desktop:py-14">
                  <p className="title-center">Monthly</p>
                  <div className="h-4"></div>
                  <p className="txt-center"> $9.90/Month</p>
                  <p className="txt-center-g italic">VAT Included</p>
                  <br></br>
                  <div className="">
                    <div className="h-32 pt-4">
                      <div className="pb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#9ca3af"
                          className=" translate-x-0.5 translate-y-[10px] float-left mr-2 ml-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                        <div className="pt-2"></div>
                        <p className="txt-left-g">Unlimited Access</p>
                
                        <div className="pt-2"></div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#9ca3af"
                          className=" translate-x-0.5 translate-y-0.5 float-left mr-2 ml-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                        <p className="txt-left-g">
                        
                            Classic & phrases presets
                        </p>


                        <div className="pt-2"></div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#9ca3af"
                          className=" translate-x-0.5 translate-y-0.5 float-left mr-2 ml-2 "
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                        <p className="txt-left-g">Cancel Anytime</p>

                        <div className="pt-2"></div>


                      <div className="rounded-xl items flex border-2 border-gray-800 p-3 m-2">
                        <img className="rounded-lg w-24 "src={Centaura}></img>
                        <p className="txt-left-g ml-4 mt-3 ">+ CENTAURA Trap & Supertrap midi kit </p>

</div>
                        <div className="mt-6 h-24">
                          <Link
                            onClick={() => {
                              //set_price_id_to_api(price1);
                              scrollToTop();
                              selectplan(price1);
                            }}
                            className="btn
                           txt-center"
                          >
                            {btn}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full desktop:w-1/4 float-left p-2">
              <div className="p-2 border-2  border-indigo-600 mb-2 desktop:mb-6 pb-52 bg-gray-800 rounded-2xl pt-6">
                <div className="bg-indigo-600 w-24 p-0.5 rounded-2xl ml-auto mr-auto -translate-y-2">
                  <p className="txt-center">Popular</p>
                </div>
                <div className="desktop:pb-14  pb-3 pt-2 desktop:pt-10">
                  <p className="title-center"> Quarterly</p>
                  <div className="h-4"></div>
                  <p className="txt-center"> $8.90/Month</p>
                  <p className="txt-center-g italic">VAT Included</p>
                  <p className="txt-center-g">Pay $26.70</p>
                  <div className="pl-3 pr-3">
                    <div className="h-32 pt-4">
                      <div className="pb-3 text-indigo-600">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#9ca3af"
                          className=" translate-x-0.5 translate-y-[10px] float-left mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                        <div className="pt-2"></div>
                        <p className="txt-left-g ml-4">Unlimited Access</p>
                
                <div className="pt-2"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#9ca3af"
                  className=" translate-x-0.5 translate-y-0.5 float-left mr-2 "
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
                <p className="txt-left-g">
                
                    Classic & phrases presets
                </p>


                <div className="pt-2"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#9ca3af"
                  className=" translate-x-0.5 translate-y-0.5 float-left mr-2 "
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
                <p className="txt-left-g mb-2">Cancel Anytime</p>

                <div className="pt-2"></div>


              <div className="rounded-xl items flex border-2 border-gray-700 p-3 mb-2 ">
                <img className="rounded-lg w-24 "src={Centaura}></img>
                <p className="txt-left-g ml-4 mt-3 ">+ CENTAURA Trap & Supertrap midi kit </p>

</div>
                        <div className="mt-6">
                          <Link
                            onClick={() => {
                              //set_price_id_to_api(price2);
                              scrollToTop();
                              selectplan(price2);
                            }}
                            className="btn-indigo txt-center"
                          >
                            {btn}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full desktop:w-1/4 p-2 float-left">
              <div className="p-2 border-2 mt-3 border-gray-800 mb-2 desktop:mb-6 pb-52 bg-gray-900 rounded-2xl pt-6">
                <div className="py-6 desktop:py-14">
                  <p className="title-center">Yearly</p>
                  <div className="h-4"></div>
                  <p className="txt-center"> $6.90/Month</p>
                  <p className="txt-center-g italic">VAT Included</p>
                  <p className="txt-center-g">Pay $82.80</p>
                  <div className="pl-3 pr-3">
                    <div className="h-32 pt-4 ml-2">
                      <div className="pb-3 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#9ca3af"
                          className=" translate-x-0.5 translate-y-[10px] float-left mr-2 m"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                        <div className="pt-2"></div>
                        <p className="txt-left-g">Unlimited Access</p>
                
                <div className="pt-2"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#9ca3af"
                  className=" translate-x-0.5 translate-y-0.5 float-left mr-2 "
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
                <p className="txt-left-g">
                
                    Classic & phrases presets
                </p>


                <div className="pt-2"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#9ca3af"
                  className=" translate-x-0.5 translate-y-0.5 float-left mr-2  "
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
                <p className="txt-left-g">Cancel Anytime</p>

                <div className="pt-2"></div>


              <div className="rounded-xl items flex border-2 border-gray-800 p-3">
                <img className="rounded-lg w-24 "src={Centaura}></img>
                <p className="txt-left-g ml-4 mt-3 ">+ CENTAURA Trap & Supertrap midi kit </p>

</div>
                <div className="mt-6 h-24">
                  <Link
                    onClick={() => {
                      //set_price_id_to_api(price1);
                      scrollToTop();
                      selectplan(price3);
                    }}
                    className="btn
                   txt-center"
                  >
                    {btn}
                  </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full desktop:w-1/4 mb-12 desktop:mb-0 float-left p-2 ">
              <div className="p-2 border-2 mt-3 border-gray-800 mb-2 desktop:mb-6 pb-52 bg-gray-900 rounded-2xl desktop:pt-6">
                <div className="py-6 desktop:py-14">
                  <p className="title-center">Lifetime</p>
                  <div className="h-4"></div>
                  <p className="txt-center"> $399.00</p>
                  <p className="txt-center-g italic">VAT Included</p>
                  <p className="txt-center-g">Pay Once</p>
                  <div className="pl-3 pr-3">
                    <div className="h-32 pt-4">
                      <div className="pb-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#9ca3af"
                          className=" translate-x-0.5 translate-y-[10px] float-left mr-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>
                        <div className="pt-2"></div>
                        <p className="txt-left-g ml-4">Unlimited Access</p>
                
                <div className="pt-2"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#9ca3af"
                  className=" translate-x-0.5 translate-y-0.5 float-left mr-2 "
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
                <p className="txt-left-g">
                
                    Classic & phrases presets
                </p>


                <div className="pt-2"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="#9ca3af"
                  className=" translate-x-0.5 translate-y-0.5 float-left mr-2 "
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </svg>
                <p className="txt-left-g">Cancel Anytime</p>

                <div className="pt-2"></div>


              <div className="rounded-xl items flex border-2 border-gray-800 p-3 ">
                <img className="rounded-lg w-24 "src={Centaura}></img>
                <p className="txt-left-g ml-4 mt-3 ">+ CENTAURA Trap & Supertrap midi kit </p>

</div>
                <div className="mt-6 h-24">

                <a href="mailto:contact@abyss.software?subject=I%20wish%20to%20subscribe%20to%20Studio%20-%20Lifetime&body=Hi%2C%0A%0AI%20wish%20to%20subscribe%20to%20Studio.%0A%0ASTEP%201%3A%20create%20an%20account%20on%20https%3A%2F%2Fabyss.software%2Fsignup%0A%0ASTEP%202%3A%20send%20us%20this%20email%0A%0ASTEP%203%3A%20you%20will%20be%20able%20to%20download%20Studio%20after%20our%20response%20from%20this%20link%3A%20https%3A%2F%2Fabyss.software%2Fdownload%0A%0ASTEP%204%3A%20you%20can%20settle%20the%20amount%20via%20the%20link%20that%20will%20be%20sent%20to%20you%20within%207%20days%20to%20keep%20access." target="_blank"      onClick={() => {
                      //set_price_id_to_api(price1);
                      scrollToTop();
                      
                    }}
                    className="btn
                   txt-center" >
Contact Sales
</a>
             
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              


































            </div>
            


            <div className="  max-w-[1000px] mx-auto">
        <div className=" mb-2 float-right mt-4  pl-1 text-white w-full  border-b-2 border-gray-800">
          <h2 className="mb-0">
            <button
              onClick={handleShow}
              className=" relative flex w-full items-center rounded-t-3xl border-0  py-3 px-3 txt-left text-base text-white transition  border-gray-800"
              type="button"
              onclick="toggle()"
            >
              When will I be charged after starting my Studio® trial period?
              <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out">
                <svg
                  ref={Arrow}
                  className=" duration-150 w-4 h-4 ml-1 float-right mt-0.5"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </span>
            </button>
          </h2>
          <div ref={Dropdown} className="hidden">
            <div id="monDiv" className="py-3  px-3 text-gray-400">
            Once your trial period is over, the first charge will be made to your previously registered account.
            </div>
          </div>
        </div>
        <div className="mb-36  float-right mt-0  pl-1 text-white w-full  border-b-2 border-gray-800">
          <h2 className="mb-0">
            <button
              onClick={handleShow2}
              className=" relative flex w-full items-center rounded-t-3xl border-0  py-3 px-3 txt-left text-base text-white transition  border-gray-800"
              type="button"
              onclick="toggle()"
            >
              Am I committing by subscribing to Studio? 
              <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out">
                <svg
                  ref={Arrow2}
                  className=" duration-150 w-4 h-4 ml-1 float-right mt-0.5"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </span>
            </button>
          </h2>
          <div ref={Dropdown2} className="hidden">
            <div id="monDiv" className="py-3 px-3 text-gray-400">
            Not at all! You can cancel your subscription anytime, even before the end of your free trial period.

            </div>
          </div>
        </div>
      </div>
          </div>
        </div></div>
        <div className={visiblepage == 2 ? "block" : "hidden"}> 
        <div className="max-w-[500px] justify-center mt-16 ml-auto mr-auto">
      <Wallpaper />
      <div className="ml-4 mr-4">
        <div className="mb-6">
          <p className="title-center">Log in</p>
        </div>
        <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 border-gray-800 ${
              !usernameValidLength && usernameTouched
                ? "border-gray-800"
                : "border-gray-800"
            } `}
            type="text"
            onChange={(j) => setValidCode(j.currentTarget.value)}
            onBlur={() => setUsernameTouched(true)}
            placeholder="Special offer Code"
          ></input>
        <input
          className={`float-right pl-3 text-white h-10 w-full border-gray-800 rounded-2xl pr-1 border-2 bg-gray-900 mb-3 `}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setemail(e.target.value)}
        />
        <div>
          <div className="mb-0">
            <input
              className="float-right pl-3 text-white h-10 w-full border-gray-800 rounded-2xl pr-1 mb-3 border-2 bg-gray-900 "
              type={passwordisVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              className="relative right-0 top-0.5 z-10"
              onClick={() => setPasswordisVisible(!passwordisVisible)}
            >
              <img
                alt=""
                className="text-gray-400 float-right -translate-y-12 mr-3"
                src={passwordisVisible ? Eye : EyeSlash}
              />
            </span>
          </div>
        </div>
        <div className="mb-2">
          <Link
            to="/signup"
            className="text-rose-200 text-center mx-auto translate-x-4 hidden "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21 "
              height="21"
              fill="currentColor"
              className=" translate-y-1  mr-1 float-left"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>{" "}

          </Link>
          <br />
        </div>
        <Link
          to="/reset"
          className="txt-center-g-u text-center mx-auto translate-x-4"
        >
          Forgot your password
        </Link>
        <div>
          <button
            onClick={() => {
              LogInVerif();
              setIsLoading(false);
            }}
            className={`float-right hover:scale-105 h-10 pt-2 rounded-2xl ml-10 pl-1 pr-1 w-full hover:duration-200 mt-4 text-center text-white mb-5 font-mt ${
              emailIsValid &&
              passwordHasValidLength &&
              passwordHasNumber &&
              passwordHasLetter
                ? "bg-indigo-600 cursor-pointer"
                : "bg-gray-800 cursor-not-allowed pointer-events-none"
            }`}
          >
            Log in
          </button>
          <div className="">
            <p className="txt-center-g">
              No account?&nbsp;
              <button
                onClick={() => {
                  scrollToTop();
                  setvisiblepage(3);
                }}

                className="txt-center-g-u"
              >
                Sign up
              </button>
            </p>
          </div>
        </div>
      </div>

      <div
        className={` ${
          isLoading ? "opacity-100" : "opacity-0"
        } transition-opacity duration-200 pointer-events-none`}
      >
        <div className="z-[55] fixed top-0 left-0">
          <div className="flex justify-center items-center w-screen bg-gray-950">
            <img
              src={LogoAbyss}
              className="w-96 animate-bounce"
              alt="logoabyss"
            />
          </div>
        </div>
      </div>
    </div>

        </div>
        
        <div className={visiblepage == 3 ? "block" : "hidden"}>

        <div className="max-w-[500px] justify-center mt-16 ml-auto mr-auto">
      <Wallpaper />
      <div className="ml-4 mr-4">
        <div className="mb-4 ">
          <p className="title-center">Sign up</p>
        </div>

        <div className="h-36">

        <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 border-gray-800 ${
              !usernameValidLength && usernameTouched
                ? "border-gray-800"
                : "border-gray-800"
            } `}
            type="text"
            onChange={(j) => setValidCode(j.currentTarget.value)}
            onBlur={() => setUsernameTouched(true)}
            placeholder="Special offer Code"
          ></input>
          <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 border-gray-800 ${
              !usernameValidLength && usernameTouched
                ? "border-rose-200"
                : "border-gray-800"
            } `}
            type="text"
            onChange={(j) => setUsername(j.currentTarget.value)}
            onBlur={() => setUsernameTouched(true)}
            placeholder="Producer Name"
          ></input>
          <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 ${
              emailTouched
                ? " border-gray-800"
                : "border-gray-800 "
            }`}
            type="email"
            placeholder="Email"
            onChange={(i) => setValidmail(i.currentTarget.value)}
            onBlur={() => setEmailTouched(true)}
          ></input>
          <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 ${
              passwordHasValidLength &&
              passwordHasNumber &&
              passwordHasLetter &&
              !passwordTouched
                ? " border-rose-200"
                : "border-gray-800 "
            }`}
            type={passwordisVisible ? "text" : "password"}
            placeholder="Password"
            id="password-field"
            onChange={(e) => setPassword(e.currentTarget.value)}
            onBlur={() => setPasswordTouched(true)}
          ></input>
          <div className="relative right-0 z-10">
            <span onClick={() => setPasswordisVisible(!passwordisVisible)}>
              <img
                alt=""
                className="text-gray-400 float-right -translate-y-12 mr-4"
                src={passwordisVisible ? Eye : EyeSlash}
              />
            </span>
          </div>
        </div>

        <div className="mt-8">
          <p
            className={` ${
              passwordHasValidLength ? "text-indigo-600" : "text-rose-200"
            }`}
          >
            Minimum 8 characters{" "}
            <img
              alt=""
              className="float-left h-[23px]  mr-2"
              src={passwordHasValidLength ? V : X}
            />
          </p>
          <br />
          <p
            className={` ${
              passwordHasLetter ? "text-indigo-600" : "text-rose-200"
            }`}
          >
            Minimum 1 Uppercase{" "}
            <img
              alt=""
              className="float-left h-[23px]  mr-2"
              src={passwordHasLetter ? V : X}
            />
          </p>
          <br />
          <p
            className={`  ${
              passwordHasNumber ? "text-indigo-600" : "text-rose-200"
            }`}
          >
            Minimum 1 number{" "}
            <img
              alt=""
              className="float-left h-[23px]  mr-2"
              src={passwordHasNumber ? V : X}
            />
          </p>
          <br />
        </div>

        <p  className="hidden text-left  mb-4 text-rose-200">
          This email is already linked to an account,{" "}
          <Link to="/login" onclick={scrollToTop}>
            <u>Log In</u>
          </Link>
        </p>

        <p className="text-left mb-4 text-rose-200">{errormsg}</p>

        <div className=" mb-3">
          <p className="txt-left-g">
            <button
              onClick={() => setNewsletterOn(!newsletterOn)}
              className={` ${
                newsletterOn ? " border-indigo-600" : " translate-x-0"
              } mr-2 float-left w-8 translate-y-0.5 h-[20px] border-2 bg-gray-900 border-gray-400 rounded-2xl `}
            >
              <div
                className={` ${
                  newsletterOn
                    ? " translate-x-3 bg-indigo-600"
                    : " translate-x-0"
                } w-[11px] h-[11px] duration-200  ml-1 rounded-full bg-gray-400 `}
              ></div>
            </button>
            I want to receive occasional news by email. No spam.
          </p>
        </div>
        <div className="mt-5 mb-6">
          <p className="txt-left-g">
            By subscribing you agree to the{" "}
            <Link to="/legal" onClick={scrollToTop} className="txt-center-g-u">
              Terms & Conditions
            </Link>{" "}
            and{" "}
            <Link to="/legal" onClick={scrollToTop} className="txt-center-g-u">
              Privacy Policy
            </Link>
          </p>
        </div>
        <div className="mb-20">
          <button
            onClick={() => {
              setIsLoading();
              registerUser();
            }}
            className={`float-right hover:scale-105 h-10 pt-2 rounded-2xl ml-10 pl-1 pr-1 w-full hover:duration-200 mt-4 text-center text-white mb-5 font-mt ${
              usernameValidLength &&
              passwordHasValidLength &&
              passwordHasNumber &&
              passwordHasLetter
                ? "bg-indigo-600 cursor-pointer"
                : "bg-gray-800 cursor-not-allowed pointer-events-none"
            }`}
          >
            Next step
          </button>
        </div>
        <div className="mt-3 mb-16">
          <p className="txt-center-g">
            Already have an account ?{" "}
            <button
              onClick={() => {
                scrollToTop();
                setvisiblepage(2);
              }}
              className="txt-center-g-u"
            >
              Log in
            </button>
          </p>
        </div>
      </div>

      <div
        className={` ${
          isLoading ? "opacity-100" : "opacity-0"
        } transition-opacity duration-200 pointer-events-none`}
      >
        <div className="z-[55] fixed top-0 left-0">
          <div className="flex justify-center items-center w-screen h-screen bg-gray-950">
            <img
              src={LogoAbyss}
              className="w-96 animate-bounce"
              alt="logoabyss"
            />
          </div>
        </div>
      </div>
    </div>
        </div>
    </div>
  );
}

export default SpecialOffer;
