import { Link } from "react-router-dom";
import AbortReason from "../elements/abortreason";
import axios from "axios";

function Abort() {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoutez cette option pour un défilement fluide
    });
  };

  const token = localStorage.getItem('token');

  const cancel = async () => {
    try {
      const response = await axios.delete("/api:o5YBY4Ce/subscription/cancel", {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token
        },
      });

      if (response.status === 200) {
        alert('Unsubscribed successfully')
      } else {
        alert('Error')
      }
    } catch (error) {
      alert('Catch')
    }
  };



  return (
    <div>
      <div className="ml-4 mr-4 mt-10 max-w-[1800px]">
        <div className="pb-4">
          <p className="title-left pt-10 mb-3">Cancel Subscription</p>
        </div>
        <div className=" w-full border-2 flex border-rose-200 rounded-2xl p-3 pb-3">
          <div className="error-input">
            <div className="pl-0 float-left pt-1"></div>
            <div className="float-right">
              <p className=" text-gray-400 float-right">
                <svg
                  className=" w-6 fill-current float-left mr-2 translate-y-0.5 text-rose-200"
                  viewBox="0 0 24 24"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 12H11v-2h2v2zm0-3H11V7h2v4z" />
                </svg>
                You are in the process of terminating your STUDIO subscription.
                You will no longer have access to the platform following your
                decision.
              </p>
            </div>
          </div>
        </div>
        <div className="h-3"></div>
      </div>

      <div className="float-left pb-9 mt-3 ml-3  w-full">
        <Link
          onClick={cancel}
          to="/account/home"
          className="txt-center-g-u"
        >
          Confirm Unsubcribe
        </Link>
      </div>
    </div>
  );
}
export default Abort;
