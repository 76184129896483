import Thanks from './thanks';
import AmbassadorHeader from '../../elements/ambassador/header';
import axios from 'axios';
import { Link } from "react-router-dom";
import Wallpaper from "../../elements/wallpaper";
import { useRef, useState } from "react";
import Eye from "../../medias/eye.svg";
import EyeSlash from "../../medias/eye-slash.svg";
import X from "../../medias/X.svg";
import V from "../../medias/V.svg";
import { useEffect } from "react";
import LogoAbyss from "../../medias/logoabyss.svg";

function AmbassadorMain() {

const [divVisible, setDivVisible] = useState(3);
const [wbar, setwbar] = useState("w-1/3");
const [token, setToken] = useState(localStorage.getItem('token')); 

const [validCode, setvalidCode] = useState("");

 
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // Ajoutez cette option pour un défilement fluide
  });
};

      //setwbar("w-2/3");
      //setDivVisible(3);

const setdiv2visible = async () => {
  try {
    const response = await axios.get("/api:sVHbJHW3/ambassador/testcode", {
      ambassador_code: validCode,
    });

    if (response.status == 200) {
      alert("success");
    } else {
      //alert("Invalid code");
    }
  } catch (error) {
    //alert("Error during test code.");
}
};




const setdiv2visible2 =  () => {
    setwbar("w-2/3");
    setDivVisible(2);
};

const setdiv3visible = async () => {
    setwbar("w-2/3");
    setDivVisible(3);
};

const setdiv4visible = async () => {
    setwbar("w-full");
    setDivVisible(4);
};

const Dropdown = useRef(null);
const Arrow = useRef(null);
const handleShow = () => {
  if (Dropdown.current) {
    Dropdown.current.classList.toggle("hidden");
    Arrow.current.classList.toggle("rotate-180");
  }
};

const Dropdown2 = useRef(null);
const Arrow2 = useRef(null);
const handleShow2 = () => {
  if (Dropdown2.current) {
    Dropdown2.current.classList.toggle("hidden");
    Arrow2.current.classList.toggle("rotate-180");
  }
};



//////////////////////////////////////////////////////////


const [isLoading, setIsLoading] = useState();

const [usernameTouched, setUsernameTouched] = useState(false);
const [username, setUsername] = useState(""); // fonction verify mdp
const usernameValidLength = username.length >= 3; //taille du mdp

const [validmail, setValidmail] = useState("");
const [emailTouched, setEmailTouched] = useState(false);
const emailIsValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
  validmail
);


const [passwordisVisible, setPasswordisVisible] = useState(false); //fonction hide show mdp

const [passwordTouched, setPasswordTouched] = useState(false);
const [password, setPassword] = useState(""); // fonction verify mdp
const passwordHasValidLength = password.length >= 8; //taille du mdp
const passwordHasNumber = /\d/.test(password); //Nombre ?
const passwordHasLetter = /[A-Z]/.test(password); //lettre ?
const [link, setLink] = useState(false);
const [newsletterOn, setNewsletterOn] = useState(true); //fonction hide show mdp

const [errormsg, setErrormsg] = useState("");
const error = useRef(null);


useEffect(() => {
  if (link) {
    window.location.href = link;
  }
}, [link]);

const registerUser = async () => {
  try {
    const response = await axios.post("/api:sVHbJHW3/ambassador/signup", {
      name: username,
      email: validmail,
      password: password,
      ambassador_code: validCode,
    });

    if (response.status == 200) {
      sendNewsletter();
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          localStorage.removeItem("token");
          localStorage.setItem("token", response.data.token);
          setwbar("w-full");
          setDivVisible(4);
         
    } else {
      error.current.classList.remove("hidden");
      setErrormsg("");
    }
  } catch (error) {
    setErrormsg("An error occurred", error);
    alert(error);
  }
};






const sendNewsletter = async () => {
  if (newsletterOn) {
  
  axios
    .post("/api:swpGnftB/newsletter", {
      email: validmail,
    })
    .then(
      (response) => {
        if (response.data.success) {
          console.log("Success newsletter")
        }
      },
      (error) => {
          console.log("error newsletter")
      }
    );

  }
};
























  const [email, setEmail] = useState("");

  const inputstat1 = useRef(null);
  const inputstat2 = useRef(null);



  useEffect(() => {
    if (link) {
      window.location.href = link;
    }
  }, [link]);

 




const LOGIN = async () => {
  try {
    const response = await axios.post("/api:sVHbJHW3/ambassador/login", {
      email: validmail,
      password: password,
      ambassador_code: validCode,
    });

    if (response.status == 200) {
      localStorage.removeItem("token");
      localStorage.setItem("token", response.data.token);
      setToken(response.data.token);
      setwbar("w-full");
      setDivVisible(4);

    } else {
      alert("error");
    }
  } catch (error) {
    alert("Catch");
  }
};

  

return (
    <div>
        <AmbassadorHeader weight={wbar} />
        <div className={divVisible === 1 ? 'block' : 'hidden' }>
        <div>
      <div className="max-w-[400px] mx-auto my-24">
        <Wallpaper />
        <p className="txt-center-g">
          Please to enter the code sended to you by email.
        </p>
        <input
          className={`input my-3 mt-4 `}
          placeholder="Ex: GHYABSJ562BS"
          onChange={(e) => setvalidCode(e.target.value)}
        ></input>
        <Link
            className=" float-right hover:scale-105 h-10 pt-2 rounded-2xl ml-10 pl-1 pr-1 w-full hover:duration-200 text-center my-3 bg-indigo-600"
          onClick={setdiv2visible}
        >
          Use Code
        </Link>
      </div>
  
    </div>
        </div>
        <div className={divVisible === 3 ? 'block' : 'hidden' }>
        <div className="max-w-[500px] justify-center mt-16 ml-auto mr-auto">
      <Wallpaper />
      <div className="ml-4 mr-4">
        <div className="mb-4 ">
          <p className="title-center">Create account</p>
        </div>
        <input
          className={`input my-4 mt-4 `}
          placeholder="Secret Code received by email"
          onChange={(e) => setvalidCode(e.target.value)}
        ></input>
        <div className="h-36">
          <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 border-gray-800 ${
              !usernameValidLength && usernameTouched
                ? "border-rose-200"
                : "border-gray-800"
            } `}
            type="text"
            onChange={(j) => setUsername(j.currentTarget.value)}
            onBlur={() => setUsernameTouched(true)}
            placeholder="Producer Name"
          ></input>
          <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 ${
              !emailIsValid && emailTouched
                ? " border-rose-200"
                : "border-gray-800 "
            }`}
            type="email"
            placeholder="Email"
            onChange={(i) => setValidmail(i.currentTarget.value)}
            onBlur={() => setEmailTouched(true)}
          ></input>
          <input
            className={`float-right pl-3 text-white h-10 w-full rounded-2xl pr-1 border-2 bg-gray-900 mb-4 ${
              !emailIsValid &&
              passwordHasValidLength &&
              passwordHasNumber &&
              passwordHasLetter &&
              !passwordTouched
                ? " border-rose-200"
                : "border-gray-800 "
            }`}
            type={passwordisVisible ? "text" : "password"}
            placeholder="Password"
            id="password-field"
            onChange={(e) => setPassword(e.currentTarget.value)}
            onBlur={() => setPasswordTouched(true)}
          ></input>
          <div className="relative right-0 z-10">
            <span onClick={() => setPasswordisVisible(!passwordisVisible)}>
              <img
                alt=""
                className="text-gray-400 float-right -translate-y-12 mr-4"
                src={passwordisVisible ? Eye : EyeSlash}
              />
            </span>
          </div>
        </div>

        <div className="mt-8">
          <p
            className={` ${
              passwordHasValidLength ? "text-indigo-600" : "text-rose-200"
            }`}
          >
            Minimum 8 characters{" "}
            <img
              alt=""
              className="float-left h-[23px]  mr-2"
              src={passwordHasValidLength ? V : X}
            />
          </p>
          <br />
          <p
            className={` ${
              passwordHasLetter ? "text-indigo-600" : "text-rose-200"
            }`}
          >
            Minimum 1 Uppercase{" "}
            <img
              alt=""
              className="float-left h-[23px]  mr-2"
              src={passwordHasLetter ? V : X}
            />
          </p>
          <br />
          <p
            className={`  ${
              passwordHasNumber ? "text-indigo-600" : "text-rose-200"
            }`}
          >
            Minimum 1 number{" "}
            <img
              alt=""
              className="float-left h-[23px]  mr-2"
              src={passwordHasNumber ? V : X}
            />
          </p>
          <br />
        </div>

        <p ref={error} className="hidden text-left  mb-4 text-rose-200">
          This email is already linked to an account,{" "}
          <Link  onclick={setdiv2visible2}>
            <u>Log In</u>
          </Link>
        </p>

        <p className="text-left mb-4 text-rose-200">{errormsg}</p>

        <div className=" mb-3">
          <p className="txt-left-g">
            <button
              onClick={() => setNewsletterOn(!newsletterOn)}
              className={` ${
                newsletterOn ? " border-indigo-600" : " translate-x-0"
              } mr-2 float-left w-8 translate-y-0.5 h-[20px] border-2 bg-gray-900 border-gray-400 rounded-2xl `}
            >
              <div
                className={` ${
                  newsletterOn
                    ? " translate-x-3 bg-indigo-600"
                    : " translate-x-0"
                } w-[11px] h-[11px] duration-200  ml-1 rounded-full bg-gray-400 `}
              ></div>
            </button>
            I want to receive occasional news by email. No spam.
          </p>
        </div>
        <div className="mt-5 mb-6">
          <p className="txt-left-g">
            By subscribing you agree to the{" "}
            <Link to="/legal" onClick={scrollToTop} className="txt-center-g-u">
              Terms & Conditions
            </Link>{" "}
            and{" "}
            <Link to="/legal" onClick={scrollToTop} className="txt-center-g-u">
              Privacy Policy
            </Link>
          </p>
        </div>
        <div className="mb-20">
          <Link
            onClick={
              registerUser}
            className={`float-right hover:scale-105 h-10 pt-2 rounded-2xl ml-10 pl-1 pr-1 w-full hover:duration-200 mt-4 text-center text-white mb-5 font-mt ${
              emailIsValid &&
              passwordHasValidLength &&
              passwordHasNumber &&
              passwordHasLetter
                ? "bg-indigo-600 cursor-pointer"
                : "bg-gray-800 cursor-not-allowed pointer-events-none"
            }`}
          >
            Next step
          </Link>
        </div>
        <div className="mt-24 max-w-[800px] mx-auto">
        <div className=" mb-2 float-right mt-4  pl-1 text-white w-full  border-b-2 border-gray-800">
          <h2 className="mb-0">
            <button
              onClick={handleShow}
              className=" relative flex w-full items-center rounded-t-3xl border-0  py-3 px-3 txt-left text-base text-white transition  border-gray-800"
              type="button"
              onclick="toggle()"
            >
              Why become an ambassador?
              <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out">
                <svg
                  ref={Arrow}
                  className=" duration-150 w-4 h-4 ml-1 float-right mt-0.5"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </span>
            </button>
          </h2>
          <div ref={Dropdown} className="hidden">
            <div id="monDiv" className="py-3  px-3 text-gray-400">
              Do you like our products? Become an ABYSS ambassador and enjoy{" "}
              <b>free lifetime access to STUDIO.</b> Additionally, you'll be
              able to communicate more easily with ABYSS members and have
              exclusive access to new products.
            </div>
          </div>
        </div>
        <div className=" mb-2 float-right mt-0  pl-1 text-white w-full  border-b-2 border-gray-800">
          <h2 className="mb-0">
            <button
              onClick={handleShow2}
              className=" relative flex w-full items-center rounded-t-3xl border-0  py-3 px-3 txt-left text-base text-white transition  border-gray-800"
              type="button"
              onclick="toggle()"
            >
              How can you become an ambassador?
              <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out">
                <svg
                  ref={Arrow2}
                  className=" duration-150 w-4 h-4 ml-1 float-right mt-0.5"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </span>
            </button>
          </h2>
          <div ref={Dropdown2} className="hidden">
            <div id="monDiv" className="py-3  px-3 text-gray-400">
              Are you a content creator? Do you post content on TikTok,
              Instagram, YouTube, etc.?{" "}
              <Link
                to="/contact"
                onClick={scrollToTop}
                className="txt-center-g-u pl-1"
              >
                Contact us
              </Link>{" "}
              to become an ambassador.
            </div>
          </div>
        </div>
      </div>
      </div>

      <div
        className={` ${
          isLoading ? "opacity-100" : "opacity-0"
        } transition-opacity duration-200 pointer-events-none`}
      >
        <div className="z-[55] fixed top-0 left-0">
          <div className="flex justify-center items-center w-screen h-screen bg-gray-950">
            <img
              src={LogoAbyss}
              className="w-96 animate-bounce"
              alt="logoabyss"
            />
          </div>
        </div>
      </div>
    </div>
        </div>











        
        <div className={ divVisible === 2 ? 'block' : 'hidden' }>
        <div className="max-w-[500px] justify-center mt-16 ml-auto mr-auto">
      <Wallpaper />
      <div className="ml-4 mr-4">
        <div className="mb-6">
          <p className="title-center">Log in</p>
        </div>
        <input
          className={`input my-3 mt-4 `}
          placeholder="Ex: GHYABSJ562BS"
          onChange={(e) => setvalidCode(e.target.value)}
        ></input>
        <input
          className={`float-right pl-3 text-white h-10 w-full border-gray-800 rounded-2xl pr-1 border-2 bg-gray-900 mb-3 `}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          ref={inputstat1}
        />
        <div>
          <div className="mb-0">
            <input
              className="float-right pl-3 text-white h-10 w-full border-gray-800 rounded-2xl pr-1 mb-3 border-2 bg-gray-900 "
              type={passwordisVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              ref={inputstat2}
            />
            <span
              className="relative right-0 top-0.5 z-10"
              onClick={() => setPasswordisVisible(!passwordisVisible)}
            >
              <img
                alt=""
                className="text-gray-400 float-right -translate-y-12 mr-3"
                src={passwordisVisible ? Eye : EyeSlash}
              />
            </span>
          </div>
        </div>
        <div className="mb-2">
          <Link

            to="/signup"
            className="text-rose-200 text-center mx-auto translate-x-4 hidden "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21 "
              height="21"
              fill="currentColor"
              className=" translate-y-1  mr-1 float-left"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>
          </Link>
          <br />
        </div>
        <Link
          to="/reset"
          className="txt-center-g-u text-center mx-auto translate-x-4"
        >
          Forgot your password
        </Link>
        <div>
          <Link
            onClick={() => {
              LOGIN();

            }}
  
            className={`float-right hover:scale-105 h-10 pt-2 rounded-2xl ml-10 pl-1 pr-1 w-full hover:duration-200 mt-4 text-center text-white mb-5 font-mt ${

              passwordHasValidLength &&
              passwordHasNumber &&
              passwordHasLetter
                ? "bg-indigo-600 cursor-pointer"
                : "bg-gray-800 cursor-not-allowed pointer-events-none"
            }`}
          >
            Log in
          </Link>
          <div className="">
            <p className="txt-center-g">
              No account?&nbsp;
              <button
                onClick={() =>{scrollToTop(); setdiv3visible();}}
                className="txt-center-g-u"
              >
                Sign up
              </button>
            </p>
          </div>
        </div>
      </div>

      <div
        className={` ${
          isLoading ? "opacity-100" : "opacity-0"
        } transition-opacity duration-200 pointer-events-none`}
      >
        <div className="z-[55] fixed top-0 left-0">
          <div className="flex justify-center items-center w-screen h-screen bg-gray-950">
            <img
              src={LogoAbyss}
              className="w-96 animate-bounce"
              alt="logoabyss"
            />
          </div>
        </div>
      </div>
    </div>
        </div>










        <div className={ divVisible === 4 ? 'block' : 'hidden' }>
            <Thanks />
        </div>
    </div>
  );
}
export default AmbassadorMain;
