import { Link } from "react-router-dom";
import Account from "../../elements/account";
import { useState } from "react";
import X from "../../medias/X.svg";
import V from "../../medias/V.svg";
import { useEffect } from "react";
import axios from "axios";
import SuccessMsg from "../../elements/successmsg";
import Error from "../../elements/errormsg";

function Accountprofile(props) {

  const token = localStorage.getItem("token");
  //-----------------------
  const [passwordisVisible, setPasswordisVisible] = useState(false); //fonction hide show mdp
  const [currentpassword, setcurrentpassword] = useState(""); // fonction verify mdp
  const [password, setPassword] = useState(""); // fonction verify mdp
  const [confirmpassword, setconfirmpassword] = useState(""); // fonction verify mdp
  const passwordHasValidLength = password.length >= 8; //taille du mdp
  const passwordHasNumber = /\d/.test(password); //Nombre ?
  const passwordHasLetter = /[A-Z]/.test(password); //lettre ?
  //-----------------------
  const [newsletterOn, setNewsletterOn] = useState(true); //fonction hide show mdp
  //-----------------------
  const [producer_name, setproducer_name] = useState("");
  const usernameValidLength = producer_name.length >= 3;
  const [email, setemail] = useState("");
  const [code, setCode] = useState("");
  //-----------------------
  const [billing_firstname, setbilling_firstname] = useState("");
  const [billing_lastname, setbilling_lastname] = useState("");
  const [billing_country, setbilling_country] = useState("");
  const [billing_address, setbilling_address] = useState("");
  const [billing_city, setbilling_city] = useState("");
  const [billing_postal, setbilling_postal] = useState("");
  const postal_int = parseInt(billing_postal, 10)
  const [code_newsletter, setCode_newsletter] = useState("");

  //---------- NEWSLETTER -----------------------------

  const sendNewsletter = async () => {
   if (newsletterOn) {
      try {
        const response = await axios.put("/mailsdelete", {
          code: code_newsletter,
          email: email
        });
  
        if (response.status === 200) {
          // Vérifiez si des données sont renvoyées
          setnotifmsg("Successfull deleted");
          setIsDescended(true);
          setNewsletterOn(false);
        } else {
          setnotifmsg2("Error");
          setIsDescended2(true);
        }
      } catch (error) {
        setnotifmsg2("Something Wrong ");
        setIsDescended2(true);
      }
    } else {
       try {
        const response = await axios.post(
          "newsletters",
          {
            email: email,
          }
        );
  
        if (response.status == 200) {
          setnotifmsg("Subscribed successfully");
          setIsDescended(true);
          setNewsletterOn(true);
        } else {
          setnotifmsg2("Error");
          setIsDescended2(true);
        }
      } catch (error) {
        setnotifmsg2("Something Wrong ");
        setIsDescended2(true);
      }





    }
  };



  

  useEffect(() => {
    const newsletterInfos = async () => {
      try {
        const response = await axios.get("/api:swpGnftB/newsletter/get", {
          headers: {
          },
        });
        
        if (response.status === 200) { // Vérifiez si des données sont renvoyées
          setNewsletterOn(true);
          setCode_newsletter(response._id)
        } else {
          setNewsletterOn(false);
        }
      } catch (error) {
        setNewsletterOn(false);
      }
    };

    newsletterInfos(); // Appel de la fonction à l'initialisation
  }, [token]); 

  //------------------------------------------------------

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoutez cette option pour un défilement fluide
    });
  };

  //remove token

  const RemoveToken = () => {
    localStorage.removeItem("token");
    window.location.reload();
  };





  useEffect(() => {
    const handleButtonClick = async () => {
      try {
        const response = await axios.get("/api:5FCR-wUS/user/getinfos", {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token
          },
        });
        
        if (response.status === 200) { // Vérifiez si des données sont renvoyées
          setproducer_name(response.data.name);
          setemail(response.data.email);
        } else {
          console.log("Something Wrong");
        }
      } catch (error) {
        console.log("Something Wrong");
      }
    };

    handleButtonClick(); // Appel de la fonction à l'initialisation
  }, [token]); 











  // NOTIF MSG

  const [notifmsg, setnotifmsg] = useState("Message");
  const [notifmsg2, setnotifmsg2] = useState("Message");

  const [isDescended, setIsDescended] = useState(false);
   const [isDescended2, setIsDescended2] = useState(false);

  useEffect(() => {
    if (isDescended) {
      const timeout = setTimeout(() => {
        setIsDescended(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isDescended]);

    useEffect(() => {
    if (isDescended2) {
      const timeout = setTimeout(() => {
        setIsDescended2(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isDescended2]);


  const [passwordTouched, setpasswordTouched] = useState(false);










  const PostUserinfos = async () => {
    try {
      const response = await axios.patch("/api:5FCR-wUS/user/postdata", {
        name: producer_name,
        email: email,
        password: confirmpassword
      }, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token
        }
      });
  
      if (response.status === 200) {
        setIsDescended(true);
        setnotifmsg("Successfully edited");
      } else {
        setnotifmsg2("Failed");
        setIsDescended2(true);
      }
    } catch (error) {
      alert("error");
    }
  };
  














    const emailIsValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    email
  );

  //---------------

  const ChangeNameData = () => {
    setnotifmsg2("Invalid Name");
    setnotifmsg("Name updated successfully");
    PostUserinfos();
  };

  const ChangeEmailData = () => {
    setnotifmsg2("Invalid Email");
    setnotifmsg("Email updated successfully");
    PostUserinfos();
  };

  const ChangeBillingData = () => {
    setnotifmsg2("Invalid Billing Informations");
    setnotifmsg("Billing infos updated successfully");
    PostUserinfos();
  };


  return (
    <div>
      <SuccessMsg isDescended={isDescended} successmsg={notifmsg} />
      <Error isDescended2={isDescended2} successmsg={notifmsg2}/>
      <Account />
      <div className="">
        <div className="w-full mb-16">
          <div className="mb-5">
            <p className="title-left mt-12">Producer name</p>
          </div>
          <input
            placeholder="Producer name"
            className="input"
            value={producer_name}
            onChange={(e) => setproducer_name(e.currentTarget.value)}
          ></input>
          <div className="w-60 ">
            <button
              onClick={PostUserinfos}
              className={`float-right hover:scale-105 h-10 pt-2 rounded-2xl ml-10 pl-1 pr-1 w-full hover:duration-200 mt-3 text-center text-white mb-5 font-mt ${
              usernameValidLength
                ? "bg-indigo-600 cursor-pointer"
                : "bg-gray-800 cursor-not-allowed pointer-events-none"
            }`} 
            >
              <p className="-translate-y-0 ">Update name</p>
            </button>
          </div>
          <div className="mb-5 ">
            <p className="title-left ">Email</p>
          </div>
          <input
            placeholder="lastname.name@gmail.com"
            className="input"
            value={email}
            onChange={(e) => setemail(e.currentTarget.value)}
          ></input>
          <div className="w-60">
            <button
              onClick={PostUserinfos}
              className={`float-right hover:scale-105 h-10 pt-2 rounded-2xl ml-10 pl-1 pr-1 w-full hover:duration-200 mt-3 text-center text-white mb-5 font-mt ${
              emailIsValid
                ? "bg-indigo-600 cursor-pointer"
                : "bg-gray-800 cursor-not-allowed pointer-events-none"
            }`} 
            >
              <p className="  ">Update Email</p>
            </button>
          </div>

          <div className="">
            <p className="title-left ">Password</p>
          </div>
          <input
            className="input mt-4 mb-3"
            type={passwordisVisible ? "text" : "password"}
            placeholder="Current Password"
            id="password-field"
            onChange={(e) => setcurrentpassword(e.currentTarget.value)}
          ></input>
          <span onClick={() => setPasswordisVisible(!passwordisVisible)}>
          </span>
        </div>
        <input
          className="input mb-3"
          type={passwordisVisible ? "text" : "password"}
          placeholder="New Password"
          onFocus={() => setpasswordTouched(true)}
          id="password-field"
          onChange={(e) => setPassword(e.currentTarget.value)}
        ></input>
        <span onClick={() => setPasswordisVisible(!passwordisVisible)}>
        </span>
      </div>

      <div className={` float-none text-gray-400 ${passwordTouched ? "block" : "hidden"} `}>
        <p
          className={`mr-3 ${
            passwordHasValidLength ? "text-indigo-600" : "text-rose-200"
          }`}
        >
          Minimum 8 characters{" "}
          <img
            alt=""
            className="float-left h-[23px] mt-0.5 mr-2"
            src={passwordHasValidLength ? V : X}
          />
        </p>
        <br />
        <p
          className={` mr-3 ${
            passwordHasLetter ? "text-indigo-600" : "text-rose-200"
          }`}
        >
          Minimum 1 Letter{" "}
          <img
            alt=""
            className="float-left h-[23px] mt-0.5 mr-2"
            src={passwordHasLetter ? V : X}
          />
        </p>
        <br />
        <p
          className={`mb-0 mr-3 ${
            passwordHasNumber ? "text-indigo-600" : "text-rose-200"
          }`}
        >
          Minimum 1 number{" "}
          <img
            alt=""
            className="float-left h-[23px]  mt-0.5 mr-2"
            src={passwordHasNumber ? V : X}
          />
        </p>
        <br />
      </div>
      <input
        className="input"
        type={passwordisVisible ? "text" : "password"}
        placeholder="Confirm New Password"
        id="password-field"
        onChange={(e) => setconfirmpassword(e.currentTarget.value)}
      ></input>
      <span onClick={() => setPasswordisVisible(!passwordisVisible)}>
      </span>

      <div className="w-60 ">
       
        <button onClick={PostUserinfos} >
          <p             className={`float-right hover:scale-105 h-10 pt-2 rounded-2xl ml-10 pl-1 pr-1 w-full hover:duration-200 mt-3 text-center text-white mb-5 font-mt ${
              passwordHasValidLength &&
              passwordHasNumber &&
              passwordHasLetter
                ? "bg-indigo-600 cursor-pointer"
                : "bg-gray-800 cursor-not-allowed pointer-events-none"
            }`} >Update Password</p>
        </button>
         <Link
          to="/reset"
          className="txt-center-g-u mb-4 text-center mx-auto translate-x-4"
        >
          Forgot your password
        </Link>
      </div>
      <div className="mb-16"></div>


      <Link
        onClick={() => {
          scrollToTop();
          RemoveToken();
        }}
        to="/"
        class="mb-20 text-rose-200 text-mt rounded-2xl bg-gray-900 hover:bg-gray-800 border border-rose-200 p-2 pl-6 pr-6 button-standard-line flex pt-1.5 float-left "
      >
        Log out
      </Link>

    </div>
  );
}
export default Accountprofile;
