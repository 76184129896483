import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

function Product(props) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [data, setData] = useState(null);
  const searchId = props.uuid; // ID que vous recherchez
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    axios
      .get("/api:t8csSOE_/soundkits")
      .then((response) => {
        // Filtrer les données pour obtenir l'élément correspondant à l'ID recherché
        const item = response.data.find((item) => item.id == searchId);
        setData(item); // Définir l'élément dans l'état des données
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erreur lors de la requête :", error);
      });
  }, []);

  return (
    <div className="mt-4 ">
      {isLoading ? (
        <div className="float-left w-full">
          <div
            className={
              "rounded-2xl aspect-square opacity-30 bg-gradient-to-br block from-gray-800 to-gray-900"
            }
          >
            ‎{" "}
          </div>

          <div className="float-left mt-4 mb-2">
            <div className="flex">
              <p className="txt-left">
                <strong>●●●●</strong>
              </p>
              <p className="txt-right-g float-right ml-2 mb-2 ">●●●</p>
            </div>
          </div>
          <Link
            onClick={scrollToTop}
            className="btn-indigo  text-center text-white"
          ></Link>
        </div>
      ) : (
        <div>
          {" "}
          {data && (
            <div className="float-left ">
              <Link
                onClick={scrollToTop}
                to={`/product/${data.id}`}
                className="bg-gray-800 rounded-2xl aspect-square shadow-2xl shadow-black"
              >
                <div
                  className={
                    isImageLoaded
                      ? "hidden"
                      : "rounded-2xl aspect-square opacity-30 bg-gradient-to-br block from-gray-800 to-gray-900"
                  }
                ></div>{" "}
                <img
                  className={
                    isImageLoaded
                      ? " rounded-2xl hover:scale-[1.02] duration-150 block"
                      : "hidden"
                  }
                  src={data.cover_path.url}
                  onLoad={() => setIsImageLoaded(true)}
                />
              </Link>

              <div className="float-left mt-4 mb-2">
                <div className="desktop:flex">
                  <p className="txt-left ">
                    <strong>{data.name}</strong>
                  </p>
                  <p className="desktop:txt-right-g txt-left-g desktop:float-right desktop:ml-2 mb-2 ">
                    {data.category}
                  </p>
                </div>
              </div>
              <Link
                onClick={scrollToTop}
                to={`/product/${data.id}`}
                className="btn-indigo text-center text-white"
              >
                <b>Buy for ${data.price.toFixed(2)}</b>
              </Link>
            </div>
          )}
        </div>
      )}
      {/**/}
    </div>
  );
}

export default Product;
