import React, { useState, useEffect } from 'react';
import LogoAbyss from '../medias/logoabyss.svg';
import LogIn from './login';

function LoaderLogIn() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.addEventListener('load', () => {
      setIsLoading(false);
    });

    return () => {
      window.removeEventListener('load', () => {});
    };
  }, []);

  return (
    <div>
   {/* <div className={` ${isLoading ? 'opacity-100' : 'opacity-0'} transition-opacity pointer-events-none`}>
        <div className='z-[55] fixed top-0 left-0'>
          <div className="flex justify-center items-center w-screen h-screen bg-gray-950">
            <img
              src={LogoAbyss}
              className="w-96 animate-bounce"
              alt="logoabyss"
            />
          </div>
        </div>
    </div>*/}
    </div>
  );
}

export default LoaderLogIn;
