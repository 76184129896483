import { useState } from "react";
import Product from "../elements/product";
import axios from "axios";
import { useEffect } from "react";
import LoaderLogIn from "./loaderlogin";
import ReactPlayer from "react-player";

function SoloProduct(props) {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  const token = localStorage.getItem("token");

  const [stripe_data, setStripe_data] = useState("");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add a loading state
  const [selectedButton, setSelectedButton] = useState("Full");
  const [link, setLink] = useState();
  const searchId = props.product_id;
  const [selectedVersion, setSelectedVersion] = useState(3);
  const [kitid, setkidit] = useState(data._id);
  const [selected_id_price, setSelected_id_price] = useState(""); // Add a loading state
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    if (link) {
      window.location.href = link;
    }
  }, [link]);

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    if (buttonName === "Basic") {
      setSelectedVersion(1);
      setSelected_id_price(data.cat1_stripeID);
    } else if (buttonName === "Producer") {
      setSelectedVersion(2);
      setSelected_id_price(data.cat2_stripeID);
    } else if (buttonName === "Full") {
      setSelectedVersion(3);
      setSelected_id_price(data.cat3_stripeID);
    }
  };

  const priceText =
    selectedVersion === 1
      ? data.cat_1_price
      : selectedVersion === 2
      ? data.cat_2_price
      : selectedVersion === 3
      ? data.cat_3_price
      : "";

  useEffect(() => {
    axios
      .get("/api:t8csSOE_/soundkits")
      .then((response) => {
        const item = response.data.find((item) => item.id == searchId);
        setData(item);
        setLoading(false); // Set loading to false when data is fetched
        setStripe_data(item.stripe_ID);
      })
      .catch((error) => {
        console.error("Erreur lors de la requête :", error);
        setLoading(false); // Set loading to false in case of an error
      });
  }, [searchId]);

  // Conditional rendering based on the loading state
  if (loading) {
    // Affichez un écran noir tant que la requête est en cours
    return <LoaderLogIn/>;
  }

  
  



  const getStripe = async () => {
    try {
      // Vérifier si un token existe dans le local storage
      const token = localStorage.getItem('token');
  
      if (!token) {
        // Rediriger l'utilisateur vers la route /soundkits/signup s'il n'y a pas de token
        window.location.href = '/soundkits/signup';
        return;
      }
  
      // Faire la requête POST avec axios en incluant le token
      const response = await axios.post("/api:o5YBY4Ce/purchase_kit", {
        price_id: stripe_data,
      }, {
        headers: {
          Accept: 'application/json',
            'Content-Type': 'application/json',
          Authorization: localStorage.getItem('token')
  
        },
      });
  
      if (response.status == 200) {
        // Mettre à jour l'URL
        setLink(response.data.result_1.url);
      } else {
        // Mettre à jour la page et le poids en cas d'erreur
        setLink("/soundkits/signup");
        window.location.reload();
      }
    } catch (error) {
      // Gérer les erreurs et mettre à jour la page et le poids
      setLink("/soundkits/signup");
      window.location.reload();
    }
  };



  return (
    <div className="max-w-[1300px] mx-auto">
      <div className="desktop:mt-4  mx-3">
        <div className="float-left desktop:mt-4 grid desktop:grid-cols-2 gap-5 mb-8 rounded-2xl p-7 bg-gray-900 justify-center ">
          <div className=" w-full ">
           
            <div className={isImageLoaded ? 'hidden' : 'rounded-2xl aspect-square opacity-30 bg-gradient-to-br block from-gray-800 to-gray-900'}></div>
            <img
              className={isImageLoaded ? 'rounded-2xl block' : 'hidden'}
              src={data.cover_path.url}
              onLoad={() => setIsImageLoaded(true)}
            />
          </div>
          <div>
            <div className=" w-full float-left">
              <p className="title-left">{data.name}</p>
              <p className="txt-left-g mt-2">{data.category}</p>
              <div className="float-left w-full mb-4">
                <div className="float-left mt-6 border-gray-800  border-2 rounded-xl p-0.5 px-2">
                  <p className="txt-left-g">{data.items_quantity}+ Items</p>
                </div>
              </div>
              <div className=" mb-6 ">
                <p className="title-left mb-2 duration-300">${data.price} USD</p>
                <p className="txt-left-g ">Incl. VAT</p>
              </div>
              <div className="">
                <button
                  onClick={getStripe}
                  to="/checkout/soundkit"
                  className=" btn-indigo  text-white text-center "
                >
                  <p className="-translate-y-4">
                    SHOP NOW{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="106"
                      height="60"
                      className="inline-block scale-75 -translate-y-0.5"
                      fill-rule="evenodd"
                      fill="#FFFFFF"
                    >
                      <path d="M101.547 30.94c0-5.885-2.85-10.53-8.3-10.53-5.47 0-8.782 4.644-8.782 10.483 0 6.92 3.908 10.414 9.517 10.414 2.736 0 4.805-.62 6.368-1.494v-4.598c-1.563.782-3.356 1.264-5.632 1.264-2.23 0-4.207-.782-4.46-3.494h11.24c0-.3.046-1.494.046-2.046zM90.2 28.757c0-2.598 1.586-3.678 3.035-3.678 1.402 0 2.897 1.08 2.897 3.678zm-14.597-8.345c-2.253 0-3.7 1.057-4.506 1.793l-.3-1.425H65.73v26.805l5.747-1.218.023-6.506c.828.598 2.046 1.448 4.07 1.448 4.115 0 7.862-3.3 7.862-10.598-.023-6.667-3.816-10.3-7.84-10.3zm-1.38 15.84c-1.356 0-2.16-.483-2.713-1.08l-.023-8.53c.598-.667 1.425-1.126 2.736-1.126 2.092 0 3.54 2.345 3.54 5.356 0 3.08-1.425 5.38-3.54 5.38zm-16.4-17.196l5.77-1.24V13.15l-5.77 1.218zm0 1.747h5.77v20.115h-5.77zm-6.185 1.7l-.368-1.7h-4.966V40.92h5.747V27.286c1.356-1.77 3.655-1.448 4.368-1.195v-5.287c-.736-.276-3.425-.782-4.782 1.7zm-11.494-6.7L34.535 17l-.023 18.414c0 3.402 2.552 5.908 5.954 5.908 1.885 0 3.264-.345 4.023-.76v-4.667c-.736.3-4.368 1.356-4.368-2.046V25.7h4.368v-4.897h-4.37zm-15.54 10.828c0-.897.736-1.24 1.954-1.24a12.85 12.85 0 0 1 5.7 1.47V21.47c-1.908-.76-3.793-1.057-5.7-1.057-4.667 0-7.77 2.437-7.77 6.506 0 6.345 8.736 5.333 8.736 8.07 0 1.057-.92 1.402-2.207 1.402-1.908 0-4.345-.782-6.276-1.84v5.47c2.138.92 4.3 1.3 6.276 1.3 4.782 0 8.07-2.368 8.07-6.483-.023-6.85-8.782-5.632-8.782-8.207z" />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="23"
                      fill="currentColor"
                      className="inline-block -translate-y-0.5"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.351.351 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91.379-.27.712-.603.993-1.005a4.942 4.942 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.687 2.687 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.695.695 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016c.217.124.4.27.548.438.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.873.873 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.352.352 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32.845-5.214Z" />
                    </svg>
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="title-center mb-6 ">Description</p>
        <p className=" text-gray-400 text-center mb-16">
        {data.description}
        </p>

        <p className="title-center mb-6">Discover in video</p>
        <div className="rounded-full mb-12">
        <div className="max-w-screen-lg mx-auto  aspect-video">
      <ReactPlayer
         url={data.youtube_link}
        controls
        position= "absolute"
        top= "0"
        left="0"
        width= "100%"
        height= "100%"
      />
    </div>
     
        </div>
        <div className="  line mb-6 "></div>
        <div className="w-full  ">
          <p className="desktop:title-left title-center">Our Commitements</p>
        </div>
        <table className="text-center text-white w-full  mb-6 mt-10">
          <tr className="pl-6">
            <th className="desktop:txt-left-b mb-3">100% royalty free</th>
            <th className="desktop:txt-left-b">Moneyback</th>
          </tr>
          <tr>
            <td className="desktop:txt-left-g text-gray-400 px-3 desktop:pr-6">
              All kits are <b>royalty-free</b> but are not to be resold or
              reposted. No need to credit us.
            </td>
            <td className="desktop:txt-left-g text-gray-400 desktop:pr-6">
              If you're unsatisfied with our kits email us within{" "}
              <b>14 days to get a refund.</b>
            </td>
          </tr>
          <tr className="">
            <th className="desktop:txt-left-g  px-3 desktop:pr-6">
              100% custom
            </th>
            <th className="desktop:txt-left-g  px-3 desktop:pr-6">
              Instant delivery
            </th>
          </tr>
          <tr>
            <td className="desktop:txt-left-g text-gray-400 px-3 desktop:pr-6">
              All kits are <b>created from scratch</b> by us.
            </td>
            <td className="desktop:txt-left-g text-gray-400 px-3 desktop:pr-6">
              All purchases are <b>delived instantly</b>, on your account page.
            </td>
          </tr>
        </table>
        <div className="line desktop:hidden mb-6 mt-6"></div>
        <div></div>
        <div className="line mb-6"></div>
        <p className="title-center mb-6">Compatibility</p>
        <table className="text-center text-white w-full">
          <tr className="">
            <th className="txt-center">All music software & DAW</th>
            <th className="txt-center">Windwos & MacOS</th>
          </tr>
          <tr>
            <td className="txt-center-g desktop:pr-6">
              Compatible with <b>all music software & DAW</b> (FL Studio,
              Ableton, Logic pro x, pro tools and more).
            </td>
            <td className="txt-center-g desktop:pr-6">
              Compatible with <b>mac/os & windows.</b> ZIP or 7ZIP requiered to
              extract.
            </td>
          </tr>
        </table>
        <div className="line mb-6 mt-6">
 
        </div>
        <p className="title-center mb-6 mt-24">Other kits from abyss</p>
        <div className="float-left grid grid-cols-2 desktop:grid-cols-4 gap-8 mb-20">
      <Product uuid="5"/>
      <Product uuid="6"/>
      <Product uuid="7"/>
      <Product uuid="8"/>

    </div>
      </div>
    </div>
  );
}
export default SoloProduct;
