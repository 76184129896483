import { Link } from "react-router-dom";
import logo from "../../medias/logo.svg";

function StudioFunnelHeader() {
    

  return (
<div> <Link to="/discover" className=" -translate-x-1 top-4 absolute duration-150 hover:bg-gray-800 bg-gray-900 border-gray-800 border desktop:scale-100 scale-90 rounded-2xl p-2 rotate-180 px-3">➜</Link><img
              src={logo}
              className="desktop:mt-12 mb-4 w-64 mx-auto z-50"
              alt="logo"
            /></div>
  );
}
export default StudioFunnelHeader;
