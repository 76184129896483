import { useState } from "react";
import { Link, redirect } from "react-router-dom";
import axios from "axios"
import { saveAs } from 'file-saver';
import { useEffect } from "react";
import Error from "./errormsg";

function DownloadBlock() {

  const [link, setLink] = useState(false);
  const [url, setUrl] = useState(false);
  const token = localStorage.getItem("token");
  const [progress, setProgress] = useState(0);

  const notifmsg = "Download failed";


  const downloadFile = async () => {
    try {
      const response = await fetch(url);
      const data = await response.blob();

      // Crée un lien temporaire pour télécharger le fichier
      const url2 = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url2;
      link.setAttribute('download', 'Studio Setup.exe');
      document.body.appendChild(link);
      link.click();

      // Libère les ressources
      window.URL.revokeObjectURL(url2);
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier :', error);
    }
  };







  //DOWNLOAD de STUDIO pour win

  const down = async () => {
    try {
      const response = await axios.post(
        "/api:bUlrPTKD/studio/downloads",
        {
          token : token
        }, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token
          }
        
        });
  
      if (response.status == 200) {
        setUrl(response.data.url);
        window.open(response.data.url, '_blank');
      } else {
        setIsDescended(true);      }
  
    } catch (error) {
      setIsDescended(true);    }
  };

  const down2 = async () => {
    try {
      const response = await axios.post(
        "/api:bUlrPTKD/studio/downloads/macOS",
        {
          token : token
        }, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token
          }
        
        });
  
      if (response.status == 200) {
        setUrl(response.data.url);
        window.open(response.data.url, '_blank');
      } else {
        setIsDescended(true);      }
  
    } catch (error) {
      setIsDescended(true);    }
  };


  const [isDescended, setIsDescended] = useState(false);

  useEffect(() => {
    if (isDescended) {
      const timeout = setTimeout(() => {
        setIsDescended(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isDescended]);
  
  ////////////////////////////////////
  
  const downloadStudio = async () => {
    try {
      const requestBody = {
        os: "WINDOWS"
      };
  
      const response = await axios.post("/studio/download", requestBody, {
        headers: {
          Session: token
        },
        responseType: 'blob'
      });
  
      if (response.status == 200) {
        // Obtenez le nom du fichier à partir des en-têtes de réponse s'il est disponible.
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Studiobyabyss.exe'; // Nom par défaut si le nom du fichier n'est pas disponible.
  
        if (contentDisposition) {
          const matches = contentDisposition.match(/filename="(.+)"/);
          if (matches && matches.length > 1) {
            fileName = matches[1];
          }
        }
  
   
        saveAs(response.data, fileName);
      } else {
        alert("Une erreur s'est produite lors du téléchargement.");
      }
    } catch (error) {
      alert("Une erreur s'est produite lors du téléchargement.");
    }
  };

  const downloadStudio2 = async () => {
    try {
      const requestBody = {
        os: "MAC"
      };
  
      const response = await axios.post("/studio/download", requestBody, {
        headers: {
          Session: token
        },
        responseType: 'blob'
      });
  
      if (response.status == 200) {
        // Obtenez le nom du fichier à partir des en-têtes de réponse s'il est disponible.
        const contentDisposition = response.headers['content-disposition'];
        let fileName = 'Studiobyabyss-macOS.exe'; // Nom par défaut si le nom du fichier n'est pas disponible.
  
        if (contentDisposition) {
          const matches = contentDisposition.match(/filename="(.+)"/);
          if (matches && matches.length > 1) {
            fileName = matches[1];
          }
        }
  
        saveAs(response.data, fileName);
      } else {
        alert("Une erreur s'est produite lors du téléchargement.");
      }
    } catch (error) {
      alert("Une erreur s'est produite lors du téléchargement.");
    }
  };
  

  const handleButtonClick = async () => {
    try {
      const response = await axios.get("/user", {
        headers: {
          Session: token
        },
      });

      if (response.status == 200) {
        downloadStudio();
      } else {
        setLink("/login");
      }
    } catch (error) {
      setLink("/login");
  }
  };

  const handleButtonClick2 = async () => {
    try {
      const response = await axios.get("/user", {
        headers: {
          Session: token
        },
      });

      if (response.status == 200) {
        downloadStudio2();
      } else {
        setLink("/login");
      }
    } catch (error) {
      setLink("/login");
  }
  };

  const openWindows = () => {
    window.open('https://mega.nz/file/tHUR0RJD#uSTzTaZ6YuZpvlRfR6bR4LwBXMC4rDSu7KcW53flPFo', '_blank');
  };

  const openMacOS = () => {
    window.open('https://mega.nz/file/H09gWJoB#lCeoZhO43lE9weTTnNEep5tL9W9nqbtQW7CTsQDpPzk', '_blank');
  };

  return (
    <div>
   
      <div className="w-full pt-6">
      <Error isDescended2={isDescended} successmsg={notifmsg}/>
        <div className="border-gray-800 desktop:pl-8 bg-gray-900 border-2 h-32 rounded-2xl p-4">
          <div className="rounded-2xl h-full mt-6 desktop:mr-8 float-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="white"
              className="bi bi-microsoft"
              viewBox="0 0 16 16"
            >
              <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
            </svg>
          </div>
          <div className="float-left desktop:pt-6 desktop:pl-0 pl-3 pt-9">
            <p className="txt-left">Windows</p>
            <div className="hidden desktop:block">
              <p className="txt-left-g">
                Download installer and follow the instructions.
              </p>
            </div>
          </div>
          <div className="float-right pt-8 desktop:pr-2 ">
            <Link onClick={openWindows} to={link} className="btn-indigo"> {/**/}
              <p className="txt-center">Download <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="inline-block ml-1 -translate-y-0.5" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></p>
            </Link>
          </div>
        </div>
      </div>
      
      <div className="w-full pt-6 pb-5 ">
        <div className="border-gray-800 desktop:pl-8 bg-gray-900 border-2 h-32 rounded-2xl p-4">
          <div className="rounded-2xl h-full mt-4 desktop:mr-8 float-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              fill="white"
              className="bi bi-apple"
              viewBox="0 0 16 16"
            >
              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
              <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z" />
            </svg>
          </div>
          <div className="float-left desktop:pt-7  desktop:pl-0 pl-3 pt-9 ">
            <p className="txt-left">MacOS</p>
            <div className="hidden desktop:block">
              <p className="txt-left-g">
                Download installer and follow the instructions.
              </p>
            </div>
          </div>
          <div className="float-right pt-7 desktop:pr-2 mt-2">
          <Link  onClick={openMacOS} to={link} className="btn-indigo"> {/**/}
              <p className="txt-center">Download <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="inline-block ml-1 -translate-y-0.5" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
</svg></p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DownloadBlock;
