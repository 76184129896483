function Legal() {

  const scrollToDiv1 = () => {
    const element = document.getElementById("1");
    if (element) {
      const rect = element.getBoundingClientRect();
      const offset = rect.top + window.pageYOffset - 140;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const scrollToDiv2 = () => {
    const element = document.getElementById("2");
    if (element) {
      const rect = element.getBoundingClientRect();
      const offset = rect.top + window.pageYOffset - 140;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const scrollToDiv3 = () => {
    const element = document.getElementById("3");
    if (element) {
      const rect = element.getBoundingClientRect();
      const offset = rect.top + window.pageYOffset - 140;

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="max-w-[1100px] justify-center mt-16 ml-auto mr-auto">
      <div className="ml-4 mr-4">
        <div className="mb-6 ">
          <p className="title-left">Legal</p>
        </div>
        <div className="mb-6">
          <p className="txt-left-b">Summary</p>
          <button onClick={scrollToDiv1} className="txt-center-g-u mr-2">
            1. Identification
          </button>
          <br></br>
          <button onClick={scrollToDiv2} className="txt-center-g-u mr-2">
            2. Terms & Conditions
          </button>
          <br></br>
          <button onClick={scrollToDiv3} className="txt-center-g-u mr-2">
            3. Privacy Policy & Cookies
          </button>
          <br></br>
        </div>
        <div className="line"></div>
      </div>
      <div className="ml-4 mr-4 mt-6">
        <div className="mb-6 mt-6">
          <p id="1" className="title-left">
            1. Identification
          </p>
        </div>
        <div className="mb-6">
          <p className="btn-left-g">
            <b>Company Identity:</b> ABYSS CO (micro-entrepreneur)
            <br></br>
            <b>
              Registration Number at the RCS ("Registre du commerce et des
              sociétés"):
            </b>{" "}
            923 271 712 00013
            <br></br>
            <b>Society email :</b> manage.abyss@gmail.com
            <br></br>
            <b>Hosting Provider Identity:</b> hostinger.fr
          </p>
        </div>
        <div className="line"></div>
        <div className="mb-6 ">
          <p id="2" className="title-left mt-6">
            2. Terms & conditions
          </p>
        </div>
        <div className="mb-6">
          <p className="txt-left-g">
            <b>Effective from 08/05/2023.</b>
            <br></br>
            <br></br>
            <b>Clause No. 1: Object and Scope</b>
            <br></br>
            These General Terms and Conditions of Sale (GTCS) form the basis of
            the commercial negotiation and are systematically provided or
            delivered to each buyer to enable them to place an order.<br></br>
            <br></br>
            The following General Terms and Conditions of Sale detail the rights
            and obligations of ABYSS CO and its customers in the context of the
            sale of the following goods: Soundkits (digital), STUDIO virtual
            instrument (digital).<br></br>
            <br></br>
            Any acceptance of the quote/order, including the clause "I
            acknowledge that I have read and accept the attached General Terms
            and Conditions of Sale," implies the buyer's unconditional adherence
            to these General Terms and Conditions of Sale.<br></br>
            <br></br>
            <b>Clause No. 2: Prices</b>
            <br></br>
            The prices of the goods sold are those in effect on the day the
            order is placed. They are stated in euros and calculated excluding
            taxes. Taxes are included in each item. For the STUDIO subscription
            system, prices may vary slightly.<br></br>
            <br></br>
            ABYSS CO reserves the right to modify its prices at any time.
            However, it undertakes to invoice the ordered goods at the prices
            indicated at the time of order registration.<br></br>
            <br></br>
            <b>Clause No. 3: Discounts and Rebates</b>
            <br></br>
            The proposed prices include any discounts and rebates that ABYSS CO
            may grant based on its results or the buyer's assumption of certain
            services.<br></br>
            <br></br>
            <b>Clause No. 4: Cash Discount</b>
            <br></br>
            No cash discount will be granted for early payment.<br></br>
            <br></br>
            <b>Clause No. 5: Payment Terms</b>
            <br></br>
            Orders can be paid by:<br></br>
            Credit card;<br></br>
            PayPal;<br></br>
            Apple Pay & Google Pay.<br></br>
            <br></br>
            <b>Clause No. 6: Late Payment</b>
            <br></br>
            The user's STUDIO subscription will be terminated in case of
            non-payment of the due amount.<br></br>
            <br></br>
            <b>Clause No. 7: Resolutive Clause</b>
            <br></br>
            If, within fifteen days following the implementation of the "Late
            Payment" clause, the buyer has not settled the outstanding amounts,
            the sale will be automatically terminated and may give rise to
            damages and interest awarded to ABYSS CO.<br></br>
            <br></br>
            <b>Clause No. 8: Retention of Title</b>
            <br></br>
            ABYSS CO retains ownership of the goods sold until full payment of
            the price, both principal and accessories. In the event that the
            buyer undergoes reorganization or judicial liquidation, ABYSS CO
            reserves the right to reclaim the sold and unpaid goods within the
            scope of the collective procedure.<br></br>
            <br></br>
            <b>Clause No. 9: Delivery</b>
            <br></br>
            ABYSS CO does not offer any delivery services.<br></br>
            <br></br>
            <b>Clause No. 10: Force Majeure</b>
            <br></br>
            ABYSS CO shall not be held liable if the non-performance or delay in
            the execution of any of its obligations described in these General
            Terms and Conditions of Sale is due to a force majeure event. Force
            majeure refers to any external, unforeseeable, and irresistible
            event within the meaning of Article 1148 of the Civil Code.<br></br>
            <br></br>
            <b>Clause No. 11: Competent Court</b>
            <br></br>
            Any dispute relating to the interpretation and execution of these
            General Terms and Conditions of Sale is subject to French law.
            <br></br>
            <br></br>
            In the absence of an amicable resolution, the dispute will be
            brought before the Commercial Court of Nantes, France.<br></br>
            <br></br>
            <b>Clause No. 12: Contact</b>
            <br></br>
            Questions about the Terms of Service should be sent to us at{" "}
            <b>manage.abyss@gmail.com</b>.<br></br>
            <br></br>
            Done in Nantes, 08/05/2023<br></br>
            Remi Dufeu
          </p>
        </div>
        <div className="line"></div>
        <div className="mb-6 mt-6 ">
          <p id="3" className="title-left">
            3. Privacy Policy & Cookies
          </p>
        </div>
        <p className="txt-left-g">
          <b>ABYSS CO Privacy Policy</b>
          <br></br>
          ABYSS CO operates the www.abyss.page website, which provides the
          SERVICE.<br></br>
          <br></br>
          <b>Information Collection and Use</b>
          <br></br>
          This page is used to inform website visitors regarding our policies
          with the collection, use, and disclosure of Personal Information if
          anyone decided to use our Service, the Abyss Co Website.<br></br>
          <br></br>
          If you choose to use our Service, then you agree to the collection and
          use of information in relation with this policy. The Personal
          Information that we collect are used for providing and improving the
          Service. We will not use or share your information with anyone except
          as described in this Privacy Policy.<br></br>
          <br></br>
          The terms used in this Privacy Policy have the same meanings as in our
          Terms and Conditions, which is accessible at www.abyss.page, unless
          otherwise defined in this Privacy Policy.<br></br>
          <br></br>
          <b>Information Collection and Use</b>
          <br></br>
          For a better experience while using our Service, we may require you to
          provide us with certain personally identifiable information, including
          but not limited to your name, phone number, and postal address. The
          information that we collect will be used to contact or identify you.
          <br></br>
          <br></br>
          <b>Log Data</b>
          <br></br>
          We want to inform you that whenever you visit our Service, we collect
          information that your browser sends to us that is called Log Data.
          This Log Data may include information such as your computer's Internet
          Protocol ("IP") address, browser version, pages of our Service that
          you visit, the time and date of your visit, the time spent on those
          pages, and other statistics.<br></br>
          <br></br>
          <b>Cookies</b>
          <br></br>
          Cookies are files with small amount of data that is commonly used an
          anonymous unique identifier. These are sent to your browser from the
          website that you visit and are stored on your computer's hard drive.
          <br></br>
          <br></br>
          Our website uses these "cookies" to collection information and to
          improve our Service. You have the option to either accept or refuse
          these cookies, and know when a cookie is being sent to your computer.
          If you choose to refuse our cookies, you may not be able to use some
          portions of our Service.<br></br>
          <br></br>
          <b>Service Providers</b>
          <br></br>
          We may employ third-party companies and individuals due to the
          following reasons:<br></br>
          To facilitate our Service;<br></br>
          To provide the Service on our behalf;<br></br>
          To perform Service-related services; or<br></br>
          To assist us in analyzing how our Service is used.<br></br>
          <br></br>
          We want to inform our Service users that these third parties have
          access to your Personal Information. The reason is to perform the
          tasks assigned to them on our behalf. However, they are obligated not
          to disclose or use the information for any other purpose.<br></br>
          <br></br>
          <b>Security</b>
          <br></br>
          We value your trust in providing us your Personal Information, thus we
          are striving to use commercially acceptable means of protecting it.
          But remember that no method of transmission over the internet, or
          method of electronic storage is 100% secure and reliable, and we
          cannot guarantee its absolute security.<br></br>
          <br></br>
          <b>Links to Other Sites</b>
          <br></br>
          Our Service may contain links to other sites. If you click on a
          third-party link, you will be directed to that site. Note that these
          external sites are not operated by us. Therefore, we strongly advise
          you to review the Privacy Policy of these websites. We have no control
          over, and assume no responsibility for the content, privacy policies,
          or practices of any third-party sites or services.<br></br>
          <br></br>
          <b>Children's Privacy</b>
          <br></br>
          Our Services do not address anyone under the age of 13. We do not
          knowingly collect personal identifiable information from children
          under 13. In the case we discover that a child under 13 has provided
          us with personal information, we immediately delete this from our
          servers. If you are a parent or guardian and you are aware that your
          child has provided us with personal information, please contact us so
          that we will be able to take necessary actions.<br></br>
          <br></br>
          <b>Changes to This Privacy Policy</b>
          <br></br>
          We may update our Privacy Policy from time to time. Thus, we advise
          you to review this page periodically for any changes. We will notify
          you of any changes by posting the new Privacy Policy on this page.
          These changes are effective immediately, after they are posted on this
          page.<br></br>
          <br></br>
          Our Privacy Policy was created with the help of the Privacy Policy
          Template.<br></br>
          <br></br>
          <b>Contact Us</b>
          <br></br>
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us.
        </p>
      </div>
    </div>
  );
}
export default Legal;
