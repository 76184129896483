import { Link } from "react-router-dom";
import Wallpaper from "../elements/wallpaper";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Error from "../elements/errormsg";
import SuccessMsg from "../elements/successmsg";

function Deletenews() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoutez cette option pour un défilement fluide
    });
  };
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const [notifmsg, setnotifmsg] = useState("Message");
  const [notifmsg2, setnotifmsg2] = useState("Message");

  const [isDescended, setIsDescended] = useState(false);
   const [isDescended2, setIsDescended2] = useState(false);
   const [email, setemail] = useState("");
   const [code_newsletter, setCode_newsletter] = useState("");

   useEffect(() => {
    if (isDescended) {
      const timeout = setTimeout(() => {
        setIsDescended(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isDescended]);

    useEffect(() => {
    if (isDescended2) {
      const timeout = setTimeout(() => {
        setIsDescended2(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isDescended2]);

  

  const reset_password = async () => {
    try {
      const response = await axios.post("/api:swpGnftB/newsletter/delete", {
        code_delete: code_newsletter,
      });
      
      if (response.status == 200) { // Vérifiez si des données sont renvoyées
        setnotifmsg("Successfull deleted");
        setIsDescended(true);
      } else {
        setnotifmsg2("Error");
        setIsDescended2(true);
      }
    } catch (error) {
      setnotifmsg2("Something Wrong ");
      setIsDescended2(true);
    }
  };



 

  return (
    <div className="max-w-[500px] justify-center mt-16 ml-auto mr-auto">
            <SuccessMsg isDescended={isDescended} successmsg={notifmsg} />
      <Error isDescended2={isDescended2} successmsg={notifmsg2}/>
      <Wallpaper />
      <div className="ml-4 mr-4">
        <div className="ml-4 mr-4">
          <div className="mb-4 ">
            <p className="title-center">Unsubscribe from newsletter</p>
          </div>
          <div className="mb-8">
            <p className="txt-center-g">Paste the secret code in the newsletter email.</p>
          </div>
          <div className="mb-24">
            <input
              className="input mb-6"
              type="text"
              placeholder="Code received by email"
              onChange={(i) => setCode_newsletter(i.currentTarget.value)}
            ></input>
          </div>
          <Link onClick={reset_password} className="btn-indigo mb-4">
            <p className="txt-center ">Unsubscribe</p>
          </Link>
         
        </div>
      </div>
    </div>
  );
}
export default Deletenews;
