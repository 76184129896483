import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Account from "../../elements/account";
import axios from "axios";

function AccountOrders() {
  const [invoices, setInvoices] = useState([]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    
    const handleButtonClick = async () => {
     
      try {
        const response = await axios.get("/api:o5YBY4Ce/subscription/list_invoices", {
          headers: {
            Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: token
          },
        });
        
        if (response.status == 200) { // Vérifiez si des données sont renvoyées
          setInvoices(response.data.result.data);

        } else {
          console.error("Failed to fetch invoices.");
        }
      } catch (error) {
        console.error("Failed to fetch invoices.");
      }
    };

    handleButtonClick(); // Appel de la fonction à l'initialisation
  }, [token]); 

  return (
    <div>
      <Account />
      <p className="title-left">Studio Invoices</p>
      <div className="pt-4 mb-6">
        <p className="txt-left-g">
          Only studio invoices appear here. Problem with your order?{" "}
          <Link to="/contact" className="txt-center-g-u pl-1">
            Contact us
          </Link>
        </p>
      </div>
      
      
      {invoices && invoices.length < 1 ? (
    <p className="txt-left-g italic">No invoices</p>
) : (
    
  invoices.map((invoice) => (
        <div >
          <p className="txt-left-g mt-6 mb-3">Invoice Date: {new Date(invoice.created * 1000).toLocaleDateString()}</p>
          <div className="border-2 border-gray-800 bg-gray-900 rounded-2xl flex p-4">
            <div className="w-full float-left m-3">
              <span className="desktop:float-left">
                <div className="mr-6">
                  <p className="txt-left-g-b mt-1"><b>Title</b></p>
                  <p className="txt-left-g">{invoice.description || "Studio subscription"}</p>
                </div>
              </span>
              <div className="mr-6">
                <span className="desktop:float-left mr-6">
                  <p className="txt-left-g-b mt-1"><b>Invoice No</b></p>
                  <p className="txt-left-g">{invoice.number}</p>
                </span>
              </div>
              <div className="mr-6">
                <span className="float-left desktop:float-left mr-6">
                  <p className="txt-left-g-b mt-1"><b>Amount</b></p>
                  <p className="txt-left-g">${invoice.amount_due.toFixed(2) / 100} {invoice.currency} </p>
                </span>
              </div>
              <div className="mr-6 hidden desktop:block">
                <span className="desktop:float-left mr-6">
                  <p className="txt-left-g-b mt-1"><b>Method</b></p>
                  <p className="txt-left-g">{invoice.collection_method}</p>
                </span>
              </div>
              <div className="mr-6">
                <span className="desktop:float-left mr-6">
                  <p className="txt-left-g-b mt-1"><b>Billing Reason</b></p>
                  <p className="txt-left-g">{invoice.billing_reason}</p>
                </span>
              </div>
              <div className="mr-6">
                <span className="desktop:float-left mr-6">
                  <p className="txt-left-g-b mt-1"><b>Paid to</b></p>
                  <p className="txt-left-g">ABYSS CO.</p>
                </span>
              </div>
              <div className="mr-6 hidden desktop:block">
                <span className="desktop:float-left mr-6">
                  <p className="txt-left-g-b mt-1"><b>Payment Status</b></p>
                  <p className="txt-left-g">{invoice.status}</p>
                </span>
              </div>
              <span className="desktop:float-right">
                <div className="desktop:float-right mt-1 relative">
                  <Link to="/contact" className="rounded-2xl hover:bg-gray-800 border-gray-800 p-2 border pl-4 pr-4 text-center text-gray-400 desktop:float-right">
                    Contact sales
                  </Link>
                </div>
              </span>
            </div>
          </div>
        </div>
        )))}
    </div>
  );
}

export default AccountOrders;
