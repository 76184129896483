import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import LogIn from "./pages/login";
import axios from "axios";
import LoaderLogIn from "./pages/loaderlogin";

const useAuth = () => {
  const [loading, setLoading] = useState(true); // État pour gérer l'affichage de l'écran de chargement
  const [user, setUser] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const handleButtonClick = async () => {
      try {
        const response = await axios.get("/api:5FCR-wUS/user/getinfos", {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token
          },
        });

        if (response.status === 200) {
          setUser({ loggedIn: true });
        } else {
          setUser({ loggedIn: false });
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false); // Une fois la requête terminée, arrêtez l'affichage de l'écran de chargement
      }
    };

    handleButtonClick();
  }, [token]);

  return { user, loading };
};

const ProtectedRoutes = () => {
  const { user, loading } = useAuth();

  if (loading) {
    // Affichez un écran noir tant que la requête est en cours
    return <LoaderLogIn/>;
  }

  return user && user.loggedIn ? <Outlet /> : <LogIn />;
};

export default ProtectedRoutes;

