import { useState } from "react";
import axios from "axios";

function Newsletter(props) {
  const [validmail, setValidmail] = useState("");
  const [messageShow, setmessageShow] = useState(false);
  const [messageSuccesShow, setmessageSuccesShow] = useState(false);
  const [erroraffich, setErroraffich] = useState("");
  const [Successaffich, setSuccessaffich] = useState("");
  const [emailTouched, setEmailTouched] = useState("");
  const emailIsValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    validmail
  );
  const [inputValue, setInputValue] = useState("");

  const Newsverif = async () => {
   setErroraffich("");
   setSuccessaffich("");
    try {
      const response = await axios.post(
        "/api:swpGnftB/newsletter",
        {
          email: inputValue,
        }
      );

      if (response.status == 200) {
        setSuccessaffich("Thank you!");

        setmessageSuccesShow(true);
      } else {
        setErroraffich("Email already used");
      }

      setmessageShow(true);
    } catch (error) {
      setErroraffich("Invalid email");
      
      setmessageShow(true);
    }
  };

  
  return (
    <div>
    <div className="w-full relative ">
    <div className="pt-3 pr-2">
        <input
          onBlur={() => setEmailTouched(true)}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="bg-gray-900 w-full pl-4 border border-gray-800 rounded-2xl p-2"
          type="email"
          placeholder="Email"
        ></input>

      </div>
      <div className="mt-3 w-24 absolute right-2 top-0 h-full">
        <button onClick={Newsverif} className="btn-indigo">
          <p className="txt-center -translate-y-1">Send</p>
        </button>
      </div>
  
    </div><div  className={`text-indigo-600 ${
            messageSuccesShow? "block" : "hidden"
          }`}>
            <p
         
        >
    
          {Successaffich}
        </p></div>
        <p
          className={`text-rose-200 float-left  ${
            !emailIsValid && emailTouched && messageShow ? "block" : "hidden"
          }`}
        >
        
          {erroraffich}
        </p>
        </div>
  );
}
export default Newsletter;
