import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { useState } from "react";
import { useEffect } from "react";
import logo from "./medias/logo.svg";
//-----------------
import "./index.css";
//-----------------
import Header from "./pages/header";
import Footer from "./pages/footer";
import Home from "./pages/home";
import Legal from "./pages/legal";
import Contact from "./pages/contact";
import FAQ from "./pages/faq";
import About from "./pages/about";
import LogIn from "./pages/login";
import Studio from "./pages/studio";
import Download from "./pages/download";
import ProductGrid from "./pages/storesoundkits";
import SoloProduct from "./pages/soloproduct";
import Abort from "./pages/abort";
import SignUp from "./pages/goStripeSignup";
import SpecialOffer from "./pages/specialoffer";
//----------------------
import AccountHome from "./pages/account/home";
import AccountHelp from "./pages/account/help";
import AccountProfile from "./pages/account/profile";
import AccountStudio from "./pages/account/studio";
import AccountSoundkits from "./pages/account/soundkits";
import AccountOrders from "./pages/account/orders";
import SoundkitConfirmation from "./pages/checkout/soundkitsconfirmation";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetpassword";
//----------------------
import Ambassador from "./pages/funnelambassadeur/ambassador";
//----------------------
import { ScrollProvider } from "./elements/ScrollProvider";
//----------------------
import NotFound from "./pages/error/404";
import axios from "axios";
//----------------------
import Protect from "./protected";
import Loader from "./pages/loader";
import OnlyStudio from "./pages/onlystudio";
import StudioThanks from "./pages/funnelambassadeur/confirmation_studio";
import Learn from "./pages/learn-studio";
import Deletenews from "./pages/delete_newsletter";
import SignKits from "./pages/soloproduct_connexion/signkits";
import LogInkit from "./pages/soloproduct_connexion/login_kits";
import Monthfree from "./pages/monthfree";
import GoStripeLogin from"./pages/goStripeLogin";
import GoStripeSignup from"./pages/goStripeSignup";
import Pricing from './pages/pricing';
import Banner from "./elements/banner";

axios.defaults.baseURL = "https://xmpt-xa8m-h6ai.n7c.xano.io";

const App = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const [loadings, setLoadings] = useState(true);

  useEffect(() => {
    // Simule un délai de chargement de 3 secondes
    const timer = setTimeout(() => setLoadings(false), 3000);

    return () => clearTimeout(timer); // Nettoie le timer pour éviter les fuites de mémoire
  }, []);

  useEffect(() => {
    axios
      .get('/api:t8csSOE_/soundkits')
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Erreur lors de la requête :', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);


  return (


  <React.StrictMode>
    
    <BrowserRouter>
    <ScrollProvider>
      
      <div className="max-w-[1300px] mx-auto">

        <div className="mt-10 mx-4">
          
          <Loader />


          <Header />
          
          <Routes>



          {data.map((product) => (
              <Route
                key={product.id}
                path={`/product/${product.id}`}
                element={
                  <SoloProduct
                    product={product}
         
                    product_id={product.id}
         
                  />
                }
              />
            ))}
            <Route path="/" element={<Studio />} />
            <Route path="/soundkits" element={<ProductGrid />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/monthfree" element={<Monthfree />} />
            <Route
              path="/faq"
              element={<FAQ title="Frequently asked question" />}
            />
            <Route path="/soundkits/signup" element={<SignKits/>}/>
            <Route path="/soundkits/login" element={<LogInkit/>}/>
            <Route path="/about" element={<About />} />
            <Route path="/discover" element={<Studio />} />
            <Route path="/download" element={<Download />} />
            <Route path="/product" element={<SoloProduct />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/abort" element={<Abort />} />
            <Route path="/confirmation" element={<SoundkitConfirmation />} />
            <Route path="/reset" element={<ForgotPassword />} />
            <Route path="/update" element={<ResetPassword />} />
            <Route path="/unsubscribe" element={<Deletenews/>}/>
            <Route path="*" element={<NotFound />} />
            <Route path="/offer" element={<SpecialOffer/>}/>
  


            <Route
              path="/signup"
              element={
                <SignUp
                  title="Sign up"
                  btntxt="Create account"
                  redirectbtn="/account/home"
                  redirect2="/login"
                />
              }
            />
            <Route
              path="/login"
              element={<LogIn redirect2="/signup" lien="/account/home" />}
            />
            <Route path="/learn" element={<Learn />} />
            <Route path="/ambassador" element={<Ambassador />} />

            <Route path="/studio" element={<OnlyStudio />} />

            <Route element={<Protect />}>
              <Route path="/thanks" element={<StudioThanks />} />
              <Route path="/account/home" element={<AccountHome />} />
              <Route path="/account/help" element={<AccountHelp />} />
              <Route path="/account/profile" element={<AccountProfile />} />
              <Route path="/account/studio" element={<AccountStudio />} />
              <Route path="/account/soundkits" element={<AccountSoundkits />} />
              <Route path="/account/orders" element={<AccountOrders />} />
            </Route>
            <Route path="/studiologin" element={<GoStripeLogin />} />
            <Route path="/studiosignup" element={<GoStripeSignup />} />

          </Routes>
        </div>
        <div className="pt-24">
          <Footer />
        </div>
      </div>
      </ScrollProvider>
    </BrowserRouter>
  </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
reportWebVitals();

