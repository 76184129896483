import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function StoreSoundkits() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [opacity, setOpacity] = useState(30);



  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get('/api:t8csSOE_/soundkits')
      .then((response) => {
        setData(response.data);
        //setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur lors de la requête :', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="ml-4 mr-4 mt-16">
    <div>
      <p className="title-left">Soundkits</p>
      <p className="txt-left-g mb-4 mt-4">
        Extend your creativity: midis, drums, and more.
      </p>
      <div className="float-left grid grid-cols-2 desktop:grid-cols-4 gap-10 mb-10">
        {loading ? (
         <p className='tx-left-g'>Loading soundkits..</p>
        ) : (
          data.map((item) => (
            <div key={item.id}>
              {data && (
                <div className="float-left ">
                  <Link
                    onClick={scrollToTop}
                    to={`/product/${item.id}`}
                    className="bg-gray-800 rounded-2xl aspect-square shadow-2xl shadow-black"
                  >
                    <div
                      className={
                        isImageLoaded
                          ? "hidden"
                          : "rounded-2xl aspect-square opacity-30 bg-gradient-to-br block from-gray-800 to-gray-900"
                      }
                    ></div>{" "}
                    <img
                      className={
                        isImageLoaded
                          ? " rounded-2xl hover:scale-[1.02] duration-150 block"
                          : "hidden"
                      }
                      src={item.cover_path.url}
                      onLoad={() => setIsImageLoaded(true)}
                    />
                  </Link>
  
                  <div className="float-left mt-4 mb-2">
                    <div className="flex">
                      <p className="txt-left">
                        <strong>{item.name}</strong>
                      </p>
                      <p className="txt-right-g float-right ml-2 mb-2 ">
                        {item.category}
                      </p>
                    </div>
                  </div>
                  <Link
                    onClick={scrollToTop}
                    to={`/product/${item.id}`}
                    className="btn-indigo text-center text-white"
                  >
                    <b>Buy for ${item.price.toFixed(2)}</b>
                  </Link>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  </div>
    
    
    
    
    
    
    
    
    );
}

export default StoreSoundkits;
