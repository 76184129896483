import React, { useState, useEffect } from 'react';
import Michenaud from "../medias/michenaud.svg";
import Advice from "../elements/advice";
import Wallpaper from "../elements/wallpaper";
import Product from "../elements/product";
import { Link } from "react-router-dom";
import Wall from "../medias/wall.png";
import ReactPlayer from "react-player";
import DAW from "../medias/daw.png";
import Plus from "../medias/+.svg";
import Pro from "../medias/pro.svg";
import Bundle from "../medias/bundle.svg";
import { BrowserRouter as Router } from 'react-router-dom';

// Importation des images du carrousel
import cover1 from "../medias/covers/cover (1).png";
import cover2 from "../medias/covers/cover (2).png";
import cover3 from "../medias/covers/cover (3).png";
import cover4 from "../medias/covers/cover (4).png";
import cover5 from "../medias/covers/cover (5).png";
import cover6 from "../medias/covers/cover (6).png"; 
import cover7 from "../medias/covers/cover (7).png";
import cover8 from "../medias/covers/cover (8).png";
import cover9 from "../medias/covers/cover (9).png"; 
import cover10 from "../medias/covers/cover (10).png";
import cover11 from "../medias/covers/cover (11).png";
import cover12 from "../medias/covers/cover (12).png"; 
import cover13 from "../medias/covers/cover (13).png";
import cover14 from "../medias/covers/cover (14).png"; 

function Loader() {
  const [showPopup, setShowPopup] = useState(false);
  const [newsletterOn, setNewsletterOn] = useState(true);
  const audioSrc = "../medias/flamenco_example.wav";

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSubscriptionSelect = (subscriptionType) => {
    localStorage.setItem('selectedSubscription', subscriptionType);
  };

  // Images et légendes du carrousel
  const images = [cover1, cover2, cover3, cover4,cover5,cover6,cover7,cover8,cover9,cover10,cover11,cover12,cover13,cover14,]; // Ajoutez vos images ici
  const captions = [
    "(20+) Centaura midi kit",
    "(240+) Deepwater vol1 FX drumkit",
    "(150+) Medusa vol1 one shot kit",
    "(80+) DF1 drumkit",
    "(200+) Acoustic drumkit vol1",
    "(200+) Acoustic drumkit vol2",
    "(50+) Gross beat bank",
    "(50+) Petrol age violin one shot kit",
    "(20+) Paralysis midi kit",
    "(240+) Falling man one shot kit",
    "(20+) Medusa vol2 one shot kit",
    "(20+) Paralysis midi kit",
    "(100+) Life is paradox",
    "(200+) Deepwater vol2 Fx drumkit",
    "(250+) Anthology ethnic one shot kit",
  ]; 

  // État et fonctions du carrousel
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <div>
      <p id="pricing" className="title-center mt-24 mb-20 ">
        Pricing
      </p>
      <div className="text-white font-mb p-4 ">
        <div className="grid grid-cols-1 desktop:grid-cols-3 gap-4 max-w-fit mx-auto">

          {/* Première carte */}
          <div className="bg-gray-900 rounded-3xl shadow-xl p-10">
            <h2 className="font-akira text-3xl mb-4 relative">Studio<img src={Plus} className="absolute w-10 top-0 left-36" alt="Icon" /> </h2>
            <p className="text-gray-400 mb-6">15-DAY MONEY-BACK GUARANTEE</p>
            <div className="flex text-4xl font-akira mb-4">            
              <span>
                $49.90
                <p className="text-gray-400 text-lg font-mt font-bold ml-2 inline-block">VAT included</p>
              </span>
            </div>
            <div className="mx-auto">
              <Link
                onClick={() => {
                  scrollToTop();
                  handleSubscriptionSelect("price_1QAVDDCdrshQWAUbe5UWRVbS");
                }}
                to="/studiosignup"
                className="btn-indigo text-center mt-6 mx-auto text-white inline-block"
              >
                Buy now
              </Link>
            </div>
            <div className="border-t-2 mt-28 border-gray-500">
              <p className="font-bold mt-6">FEATURES</p>
              <p className="text-gray-400 mt-4">➜ 135+ synth+aoucstic+phrases presets</p>
              <p className="text-rose-200 mt-4">➜ 0 integrated midi files</p>
              <p className="text-gray-400 mt-4">➜ Regular themed updates</p>
            </div>
          </div>

          {/* Deuxième carte */}
          <div className="bg-gray-900 rounded-3xl shadow-xl p-10">
            <h2 className="font-akira text-3xl mb-4 relative">Studio<img src={Pro} className="absolute w-16 top-2 left-36" alt="Icon" /> </h2>
            <p className="text-gray-400 mb-6">15-DAY MONEY-BACK GUARANTEE</p>
            <div className="flex text-4xl font-akira mb-4">
              <span>
                $79.90
                <p className="text-gray-400 text-lg font-mt font-bold ml-2 inline-block">VAT included</p>
              </span>
            </div>
            <div className="mx-auto">
              <Link
                onClick={() => {
                  scrollToTop();
                  handleSubscriptionSelect("price_1QAVDDCdrshQWAUby1DK4Q5N");
                }}
                to="/studiosignup"
                className="btn-indigo text-center mt-6 mx-auto text-white inline-block"
              >
                Buy now
              </Link>
            </div>
            <div className="border-t-2 mt-28 border-gray-500">
              <p className="font-bold mt-6">FEATURES</p>
              <p className="text-gray-400 mt-4">➜ 135+ synth+aoucstic+phrases presets</p>
              <p className="text-gray-400 mt-4">➜ 300+ integrated midi files</p>
              <p className="text-gray-400 mt-4">➜ Regular themed updates</p>
            </div>
          </div>

          {/* Troisième carte avec carrousel */}
          <div className="bg-gradient-to-br from-gray-800 to-indigo-600/50 backdrop-blur-lg rounded-3xl shadow-xl p-10 border-2 border-indigo-600">
            <h2 className="font-akira text-3xl mb-4 relative">Studio<img src={Bundle} className="absolute w-32 -top-1 left-36" alt="Icon" /> </h2>
            <p className="text-gray-400 mb-6">15-DAY MONEY-BACK GUARANTEE</p>
            <div className="flex text-4xl font-akira mb-4">
              <span>
                $99.90
                <p className="text-gray-400 text-lg font-mt font-bold ml-2 inline-block">VAT included</p>
              </span>
            </div>
            <div className="mx-auto">
              <Link
                onClick={() => {
                  scrollToTop();
                  handleSubscriptionSelect("price_1QAVDECdrshQWAUbcWMi29vj");
                }}
                to="/studiosignup"
                className="btn-indigo text-center mt-6 mx-auto text-white inline-block"
              >
                Buy now
              </Link>
            </div>
            <div className="border-t-2 mt-28 border-gray-500">
              <p className="font-bold mt-6">FEATURES</p>
              <p className="text-gray-400 mt-4">➜ 135+ synth+aoucstic+phrases presets</p>
              <p className="text-gray-400 mt-4">➜ 300+ integrated midi files</p>
              <p className="text-gray-400 mt-4">➜ Regular themed updates</p>
              <p className="text-gray-400 mt-4"> ^~^| Unlimited midi download</p>
              <p className="text-gray-400 mt-4">➜ 14 SOUNDKITS (Abyss Bundle with drumkits, FX packs & one shot kits)</p>
              <p className="text-white mt-4 bg-indigo-600 p-1 rounded-xl items-start inline-block px-3 ">2100+ items</p>

              {/* Carrousel ajouté */}
              <div className="relative w-full max-w-4xl mx-auto aspect-video mt-8">
                <div className="overflow-hidden rounded-lg">
                  <div
                    className="flex transition-transform duration-500 ease-in-out"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                  >
                    {images.map((image, index) => (
                      <div key={index} className="w-full flex-shrink-0">
                        <img src={image} alt={`Slide ${index + 1}`} className="w-1/2 object-cover mx-auto" />
                        <p className="text-center text-white mt-2">{captions[index]}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <button
                  onClick={handlePrev}
                  className="absolute top-1/2 left-2 rotate-180 transform -translate-y-1/2 p-2 bg-transparent opacity-50 -translate-x-10 text-white rounded-full focus:outline-none"
                >
                  ➜
                </button>
                <button
                  onClick={handleNext}
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 p-2 bg-transparent backdrop-blur-xl opacity-50 translate-x-10 text-white rounded-full focus:outline-none"
                >
                  ➜
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <p className="title-center mt-20">
        Compatible with
      </p>
      <img
        src={DAW}
        className="opacity-80 w-3/4 mx-auto mt-8"
        alt="Compatible DAWs"
      />
    </div>
  );
}

export default Loader;
