import { Link } from "react-router-dom";
import Wallpaper from "../../elements/wallpaper";

function SoundkitConfirmation() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoutez cette option pour un défilement fluide
    });
  };

  return (
    <div className="my-16 mx-auto max-w-[1000px]">
      <Wallpaper />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="currentColor"
        class="text-indigo-600 mx-auto mb-3"
        viewBox="0 0 16 16"
      >
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
      </svg>
      <p className="title-center">On the way to the gold record.</p>
      <p className="txt-center-g mt-3">
        Thank you for your purchase.
      </p>

      

      <p className="title-center mt-24">Download</p>
      <p className="txt-center-g my-3">
        All your kits are downloadable from the account space below. A
        confirmation email will be sent to you.
      </p>
      <div className="mx-auto w-64 mb-3">
        <Link
          to="/account/soundkits"
          className="btn-indigo text-center"
          onClick={scrollToTop}
        >
          Download your kits
        </Link>
      </div>

      <div className="mx-auto w-64 my-3">
        <Link to="/account/home" className="btn mt-3" onClick={scrollToTop}>
          View dashboard
        </Link>
      </div>
    </div>
  );
}
export default SoundkitConfirmation;
