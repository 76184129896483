import { Link } from "react-router-dom";
import Wallpaper from "../../elements/wallpaper";
import { useState, useEffect, useRef } from "react";
import Eye from "../../medias/eye.svg";
import EyeSlash from "../../medias/eye-slash.svg";
import axios from "axios";
import LogoAbyss from "../../medias/logoabyss.svg";

function LogInkit() {
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [link, setLink] = useState(false);
  const inputstat1 = useRef(null);
  const inputstat2 = useRef(null);
  const errormsg = useRef(null);
  const [passwordisVisible, setPasswordisVisible] = useState(false);
  const [password, setPassword] = useState("");
  const passwordHasValidLength = password.length >= 8;
  const passwordHasNumber = /\d/.test(password);
  const passwordHasLetter = /[a-zA-Z]/.test(password);
  const emailIsValid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
    email
  );

  const [error, setError] = useState("");

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoutez cette option pour un défilement fluide
    });
  };

  useEffect(() => {
    if (link) {
      window.location.href = link;
    }
  }, [link]);

  const LogInVerif = async () => {
    try {
      const response = await axios.post("/api:5FCR-wUS/auth/login", {
        email: email,
        password: password,
      });
  
      if (response.status == 200) {
        localStorage.removeItem("token");
        localStorage.setItem("token", response.data.token);
        window.history.back();
      } else {
        window.location.reload();
      }
    } catch (error) {
      window.location.reload();
    }
  };

  return (
    <div className="max-w-[500px] justify-center mt-16 ml-auto mr-auto">
      <Wallpaper />
      <div className="ml-4 mr-4">
        <div className="mb-6">
          <p className="title-center mb-10">Log in to purchase your kit</p>
        </div>
        <input
          className={`float-right pl-3 text-white h-10 w-full border-gray-800 rounded-2xl pr-1 border-2 bg-gray-900 mb-3 `}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          ref={inputstat1}
        />
        <div>
          <div className="mb-0">
            <input
              className="float-right pl-3 text-white h-10 w-full border-gray-800 rounded-2xl pr-1 mb-3 border-2 bg-gray-900 "
              type={passwordisVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              ref={inputstat2}
            />
            <span
              className="relative right-0 top-0.5 z-10"
              onClick={() => setPasswordisVisible(!passwordisVisible)}
            >
              <img
                alt=""
                className="text-gray-400 float-right -translate-y-12 mr-3"
                src={passwordisVisible ? Eye : EyeSlash}
              />
            </span>
          </div>
        </div>
        <div className="mb-2">
          <Link
            ref={errormsg}
            to="/signup"
            className="text-rose-200 text-center mx-auto translate-x-4 hidden "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21 "
              height="21"
              fill="currentColor"
              className=" translate-y-1  mr-1 float-left"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
            </svg>{" "}
            {error}
          </Link>
          <br />
        </div>
        <Link
          to="/reset"
          className="txt-center-g-u text-center mx-auto translate-x-4"
        >
          Forgot your password
        </Link>
        <div>
          <Link
            onClick={() => {
              LogInVerif();
              setIsLoading(true);
            }}
            to={link}
            className={`float-right hover:scale-105 h-10 pt-2 rounded-2xl ml-10 pl-1 pr-1 w-full hover:duration-200 mt-4 text-center text-white mb-5 font-mt ${
              emailIsValid &&
              passwordHasValidLength &&
              passwordHasNumber &&
              passwordHasLetter
                ? "bg-indigo-600 cursor-pointer"
                : "bg-gray-800 cursor-not-allowed pointer-events-none"
            }`}
          >
            Got to payment
          </Link>
          <div className="">
            <p className="txt-center-g">
              No account?&nbsp;
              <Link
                onclick={scrollToTop}
                to="/soundkits/signup"
                className="txt-center-g-u"
              >
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div
        className={` ${
          isLoading ? "opacity-100" : "opacity-0"
        } transition-opacity duration-200 pointer-events-none`}
      >
        <div className="z-[55] fixed top-0 left-0">
          <div className="flex justify-center items-center w-screen h-screen bg-gray-950">
            <img
              src={LogoAbyss}
              className="w-96 animate-bounce"
              alt="logoabyss"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogInkit;
