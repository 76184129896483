import React from 'react';
import Account from "../../elements/account";
import Background1 from "../../medias/paradox.png";
import Background2 from "../../medias/banner.png";
import Background3 from "../../medias/banner2.png";
import Background4 from "../../medias/flower.png";
import axios from "axios";
import { useState } from 'react';
import { useEffect } from 'react';


function AccountHome() {
  const [userName, setUserName] = useState("");
    const [user, setUser] = useState(null); // Utilisez null plutôt que false pour initialiser le state
    const token = localStorage.getItem("token");
  
    useEffect(() => {
      const handleButtonClick = async () => {
        try {
          const response = await axios.get("/api:5FCR-wUS/user/getinfos", {
            headers: {
              Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token
            },
          });
          
          if (response.status == 200) { // Vérifiez si des données sont renvoyées
            setUserName(response.data.name);
          } else {
            setUser({ loggedIn: false });
          }
        } catch (error) {
          console.error("Error:", error);
        }
      };
  
      handleButtonClick(); // Appel de la fonction à l'initialisation
    }, [token]); 
  

  return (
    <div>
      <Account/>
      <div className="w-full mb-5">
        <p className="title-left">Home</p>
      </div>
      <div className=" border-2 rounded-2xl p-4 mb-5 border-gray-800">
        <div className=" ">
          <p className="txt-left ">
            <b>Welcome back {userName}!</b>
          </p>
          <p className="txt-left-g  ">We are happy to see you again</p>
        </div>
      </div>
      <div className="w-full">



      </div>
      <div className="w-full mt-6 block">
        <p className="title-left">News</p>
      </div>
      <div>
        <div
          className="rounded-2xl p-3 mt-6  bg-middle bg-cover bg-opacity-20 "
          style={{ backgroundImage: `url(${Background4})` }}
        >
          <p className="txt-left-g mb-1 mt-3">08/04/2024</p>
          <p className="title-left mb-1">Synth flower update</p>
          <p className="txt-left-g mb-3">Discover Studio by abyss v1.2</p>
        </div>
      </div>
      <div>
        <div
          className="rounded-2xl p-3 mt-6  bg-bottom bg-cover bg-opacity-20"
          style={{ backgroundImage: `url(${Background2})` }}
        >
          <p className="txt-left-g mb-1 mt-3">03/01/2024</p>
          <p className="title-left mb-1">Ethnic journey update</p>
          <p className="txt-left-g mb-3">Discover Studio by abyss v1.1</p>
        </div>
      </div>

      <div>
        <div
          className="rounded-2xl p-3 mt-6  bg-middle bg-cover bg-opacity-20"
          style={{ backgroundImage: `url(${Background3})` }}
        >
          <p className="txt-left-g mb-1 mt-3">12/03/2023</p>
          <p className="title-left mb-1">Studio 1.0 out</p>
          <p className="txt-left-g mb-3">Try the first version of Studio by Abyss</p>
        </div>
      </div>

      <div>
        <div
          className="rounded-2xl p-3 mt-6  bg-bottom bg-cover bg-opacity-20"
          style={{ backgroundImage: `url(${Background1})` }}
        >
          <p className="txt-left-g mb-1 mt-3">07/01/2023</p>
          <p className="title-left mb-1">Abyss Website 4th generation out</p>
          <p className="txt-left-g mb-3">We are proud to present our new website for Studio</p>
        </div>
      </div>




    </div>
  );
}

export default AccountHome;
