import { Link } from "react-router-dom";
import Wallpaper from "../elements/wallpaper";
import { useState } from "react";
import axios from "axios";

function ResetPassword() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoutez cette option pour un défilement fluide
    });
  };
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const reset_password = async () => {
    try {
      const response = await axios.put("/api:5FCR-wUS/password/reset", {
        secret_key: code,
        new_password: password,
      });
  
      if (response.status == 200) {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            alert("Success")
      } else {
      }
    } catch (error) {
    }
  };

  return (
    <div className="max-w-[500px] justify-center mt-16 ml-auto mr-auto">
      <Wallpaper />
      <div className="ml-4 mr-4">
        <div className="ml-4 mr-4">
          <div className="mb-4 ">
            <p className="title-center">Update Password</p>
          </div>
          <div className="mb-8">
            <p className="txt-center-g">Enter the secret code & your new password</p>
          </div>
          <div className="mb-24">
            <input
              className="input"
              type="text"
              placeholder="Secret code received by email"
              onChange={(i) => setCode(i.currentTarget.value)}
            ></input>
          </div>
          <div className="mb-24">
            <input
              className="input mb-6"
              type="email"
              placeholder="New password"
              onChange={(i) => setPassword(i.currentTarget.value)}
            ></input>
          </div>
          <button onClick={reset_password} className="btn-indigo mb-4">
            <p className="txt-center ">Update Password</p>
          </button>
          <div className="mt-40 mb-16">
            <p className="btn-center-g">
              Remember your password ?{" "}
              <Link
                onClick={scrollToTop}
                to="/login"
                className="txt-center-g-u"
              >
                Log in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;
