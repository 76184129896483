import Wallpaper from "../../elements/wallpaper";
import { Link } from "react-router-dom";

function error404() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="max-w-[450px] justify-center mt-16 ml-auto mr-auto">
      <Wallpaper />
      <div className="ml-4 mr-4">
        <div className="h-full pt-32 pb-20">
          <h1 className="title-center">404</h1>
          <div className="mt-6 mb-6">
            <p className="txt-center">
              The page you are seeking cannot be found.
            </p>
          </div>
          <Link
            onClick={scrollToTop}
            to="/"
            className="btn-indigo text-center"
          >
            <p className="txt-center">Go Home</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default error404;
