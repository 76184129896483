function Wallpaper() {
  return (
    <img
      className=" pointer-events-none blur-3xl absolute top-0 right-0 z-50 opacity-40"
      src={require("../medias/WallpaperHome.png")}
      alt=""
    />
  );
}
export default Wallpaper;
