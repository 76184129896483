import Wallpaper from "../elements/wallpaper";

function About() {

  return (
    <div className="max-w-[800px] justify-center mt-16 ml-auto mr-auto">
      <Wallpaper />
      <div className=" mr-4">
        <div className="mb-6 ">
          <p className="title-left">About</p>
        </div>
        <div className="mb-6">
          <p className="txt-left-g">
            <b>Premium VST, soundkits & more.</b> Growth your sales with Abyss.{" "}
          </p>
        </div>
      </div>
      <div className=" mr-4">
        <div className="mb-6 ">
          <p className="title-left"> Our mission</p>
        </div>
        <div className="mb-6">
          <p className="txt-left-g">
            Abyss Co, born in 2020 in France, emerged in response to the
            increasing number of beatmakers on the internet. <br />
            <br />{" "}
            <b>
              Our primary objective is to provide indispensable tools
              thatfacilitate the growth of producers
            </b>
            , both in terms of popularity and sales.We are dedicated to
            assisting you in developing your passion and making a sustainable
            livelihood from it.
          </p>
        </div>
      </div>
      <div className="ml-4 mr-4"></div>
      <div className="line"></div>
      <div className="mb-6 mt-6 ">
        <p className="title-left"> Few numbers</p>
      </div>
      <table className="mb-6 mt-8">
        <tbody>
          <tr>
            <td>
              <p className="txt-left">
                <b>600+ kits sold</b>
              </p>
              <p className="txt-left-g">
                600+ producers trusted in abyss since 1 year.
              </p>
            </td>
            <td>
              <p className="txt-left">
                <b>10k+ Newsletter Subscribers</b>
              </p>
              <p className="txt-left-g">
                600+ producers trusted in abyss since 1 year.
              </p>
            </td>
          </tr>
          <tr>
            <td className="pt-6">
              <p className="txt-left">
                <b>25K+ free downloads</b>
              </p>
              <p className="txt-left-g">
                25k+ producers have downloaded our kits since the end of 2020.
              </p>
            </td>
            <td className="pt-6">
              <p className="txt-left">
                <b>178+ Countries</b>
              </p>
              <p className="txt-left-g">
                Producers from 178 countries have downloaded our kits.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      
      <p className="txt-left-g">Abyss co.</p>
    </div>
  );
}
export default About;
