import React from "react";
import StudioWait from "../elements/studiowait";
import { Link } from "react-router-dom";
import logo from "../medias/logo.svg";
import Wallpaper from "../elements/wallpaper";
import Studio from "./studio";

function OnlyStudio() {
  return (
    <div>
      <Studio/>
    </div>
  );
}
export default OnlyStudio;
