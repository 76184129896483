function AmbassadorHeader(props) {
  return (
    <div className="mt-10">
      <p className="title-center mt-6">Become Ambassador</p>
      <p className="txt-center-g">Less than 1mn. Free & easy</p>
      <div className="h-2 bg-gray-800 w-full rounded-full mt-4 mb-4">
        <div className={`${props.weight} h-2 duration-500 bg-indigo-600 float-left rounded-full`}></div>
      </div>
    </div>
  );
}
export default AmbassadorHeader;
