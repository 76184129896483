import { useRef } from "react";
import Wallpaper from "../elements/wallpaper";
import FAQ from "./faq";

function Learn() {
  
  const Dropdown = useRef(null);
const Arrow = useRef(null);
const handleShow = () => {
  if (Dropdown.current) {
    Dropdown.current.classList.toggle("hidden");
    Arrow.current.classList.toggle("rotate-180");
  }
};

const Dropdown2 = useRef(null);
const Arrow2 = useRef(null);
const handleShow2 = () => {
  if (Dropdown2.current) {
    Dropdown2.current.classList.toggle("hidden");
    Arrow2.current.classList.toggle("rotate-180");
  }
};

const Dropdown3 = useRef(null);
const Arrow3= useRef(null);
const handleShow3 = () => {
  if (Dropdown3.current) {
    Dropdown3.current.classList.toggle("hidden");
    Arrow3.current.classList.toggle("rotate-180");
  }
};

const Dropdown4 = useRef(null);
const Arrow4 = useRef(null);
const handleShow4 = () => {
  if (Dropdown4.current) {
    Dropdown4.current.classList.toggle("hidden");
    Arrow4.current.classList.toggle("rotate-180");
  }
};

  return (
    <div className="max-w-[1200px] justify-center mt-16 ml-auto mr-auto">
      <Wallpaper />
      <div className=" mr-4">
        <div className="mb-6 ">
          <p className="title-left">Learn Studio</p>
        </div>
        <div className="mb-6">
          <p className="tile-left" ></p>
          <p className="txt-left-g mb-16">
            <b></b>Below is a quick guide explaining how Studio by Abyss works. If you don't understand anything, we'll be happy to assist you via email at contact@abyss.software.
          </p>
        </div>
        <div className=" ">
          <p className="title-left">Quick Questions</p>






          <div className=" mx-auto">
        <div className=" mb-2 float-right mt-4  pl-1 text-white w-full  border-b-2 border-gray-800">
          <h2 className="mb-0">
            <button
              onClick={handleShow}
              className=" relative flex w-full items-center rounded-t-3xl border-0  py-3  txt-left text-base text-white transition  border-gray-800"
              type="button"
              onclick="toggle()"
            >
              How to Use Phrase Instruments?
              <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out">
                <svg
                  ref={Arrow}
                  className=" duration-150 w-4 h-4 ml-1 float-right mt-0.5"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </span>
            </button>
          </h2>
          <div ref={Dropdown} className="hidden">
            <div id="monDiv" className="py-3  text-gray-400">
            You need to select an instrument with the "phrase" icon in the studio.
You must now adjust the samples to fit your composition. <br></br><br></br>You can either use samples from scratch (starting from 0) – it's your choice – or enhance your beats with phrases. In the latter case, you need to match the key of your composition. There are many tools for this, such as Auto-Key by Antares.
Play your phrases from the piano roll of your DAW, playing notes of your preference in time to stay in tempo.
<br></br><br></br>Note: The BPM adapts to your project but can be manually adjusted.
Note: Stretching phrases is limited; excessive stretching might not yield the desired result.
            </div>
          </div>
        </div>


        </div>







        <div className=" mx-auto">
        <div className=" mb-2 float-right   pl-1 text-white w-full  border-b-2 border-gray-800">
          <h2 className="mb-0">
            <button
              onClick={handleShow2}
              className=" relative flex w-full items-center rounded-t-3xl border-0  py-3  txt-left text-base text-white transition  border-gray-800"
              type="button"
              onclick="toggle()"
            >
              What to Do If STUDIO Produces No Sound?
              <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out">
                <svg
                  ref={Arrow2}
                  className=" duration-150 w-4 h-4 ml-1 float-right mt-0.5"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </span>
            </button>
          </h2>
          <div ref={Dropdown2} className="hidden">
            <div id="monDiv" className="py-3  text-gray-400">
            Check if your computer or DAW's sound is enabled (Main sound, bus & master, track...).
If there is still no sound, go to Settings using the icon in the upper right corner, then "Audio," and change the output device, and the driver if necessary.
            </div>
          </div>
        </div>


        </div>






        





        <div className=" mx-auto">
        <div className=" mb-2 float-right pl-1 text-white w-full  border-b-2 border-gray-800">
          <h2 className="mb-0">
            <button
              onClick={handleShow3}
              className=" relative flex w-full items-center rounded-t-3xl border-0  py-3  txt-left text-base text-white transition  border-gray-800"
              type="button"
              onclick="toggle()"
            >
              How to Change Interface Size?
              <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out">
                <svg
                  ref={Arrow3}
                  className=" duration-150 w-4 h-4 ml-1 float-right mt-0.5"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </span>
            </button>
          </h2>
          <div ref={Dropdown3} className="hidden">
            <div id="monDiv" className="py-3  text-gray-400">
            To change the interface size, go to settings via the icon in the upper right corner, then change the screen size (S, M, L...).
Note: Be careful not to make the interface too large; you won't be able to navigate it.
            </div>
          </div>
        </div>


        </div>






        





        <div className=" mx-auto">
        <div className="  float-right mb-16 pl-1 text-white w-full  border-b-2 border-gray-800">
          <h2 className="mb-0">
            <button
              onClick={handleShow4}
              className=" relative flex w-full items-center rounded-t-3xl border-0  py-3  txt-left text-base text-white transition  border-gray-800"
              type="button"
              onclick="toggle()"
            >
              How to Reset My Studio Sessions?
              <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out">
                <svg
                  ref={Arrow4}
                  className=" duration-150 w-4 h-4 ml-1 float-right mt-0.5"
                  aria-hidden="true"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </span>
            </button>
          </h2>
          <div ref={Dropdown4} className="hidden">
            <div id="monDiv" className="py-3  text-gray-400">
            You are limited to 2 simultaneous screens connected to the studio. If you want to connect from a new screen, you need to log out of all your existing sessions from www.abyss.software/account/studio, then "log out from all sessions."
            </div>
          </div>
        </div>


        </div>






        </div>
        <div className="mb-6 mt-12 ">
          <p className="title-left mt-12">Documentation</p>
          <div className="border-2 my-6 mt-12 p-6 rounded-2xl border-gray-800 ">
            <p className="txt-left-g mb-3"><b>Summary</b></p>
            <button  className="txt-center-g mr-2">1. Presets</button><br></br>
            <button  className="txt-center-g mr-2 ml-8">A. Classic Instruments</button><br></br>
            <button  className="txt-center-g mr-2 ml-8">B. Phrase Instruments</button><br></br>
            <button  className="txt-center-g mr-2">2. Engine</button><br></br>
            <button  className="txt-center-g mr-2 ml-8">A. Samples & Sample Maps</button><br></br>
            <button  className="txt-center-g mr-2 ml-8">B. Effects</button><br></br>
            <button  className="txt-center-g mr-2">3. Settings</button><br></br>
            <button  className="txt-center-g mr-2 ml-8">A. Screen Size</button><br></br>
            <button  className="txt-center-g mr-2 ml-8">B. Audio Settings</button><br></br>
            <button  className="txt-center-g mr-2 ml-8">C. Log Out</button><br></br>
          </div>
          <p className="title-left mt-12 mb-6">1. Presets</p>
          <p className="txt-left mb-3"><b>A. Classic Instruments</b></p>
          <p className="txt-left-g mb-3">To use classic instruments, access the search tool on the left side of the screen and select the "classic" tag. You can browse only classic instruments.
Note: Classic instruments work like traditional VSTs; one key on your MIDI keyboard or piano roll corresponds to one note.</p>
<p className="txt-left mb-3"><b>B. Phrase Instruments</b></p>
          <p className="txt-left-g mb-3">Phrase instruments are selections of samples that you can modify in speed and pitch to enhance your beats.

Select an instrument with the "phrase" icon in the studio.<br></br><br></br>
Adjust the samples to fit your composition. You can start from scratch, it's your choice, or you can enhance your beats with phrases. Match the key of your composition. There are many tools for this, such as Auto-Key by Antares.
Play your phrases from the piano roll of your DAW, playing notes of your preference in time to stay in tempo.
Note: The BPM adapts to your project but can be manually adjusted.
Note: Stretching phrases is limited; excessive stretching might not yield the desired result.</p>




<p className="title-left mt-12 mb-6">2. Engine</p>
          <p className="txt-left mb-3"><b>A. Sample Maps</b></p>
          <p className="txt-left-g mb-3">The presets/instruments have audio sources from sample maps, accessible from Engine/Samples. There are 2 types, one for phrases, and one for classics. You can modify the sample sources as you like.</p>
<p className="txt-left mb-3"><b>B. Effects</b></p>
          <p className="txt-left-g mb-3">Effects are accessible from Engine/Effects. They can be modified according to your creation (Reverb, delay, saturation, chorus, EQ, gain, etc.).</p>





          <p className="title-left mt-12 mb-6">3. Settings</p>
          <p className="txt-left mb-3"><b>A. Screen Size</b></p>
          <p className="txt-left-g mb-3">To change the interface size, go to settings via the icon in the upper right corner, then change the screen size (S, M, L...).
Note: Be careful not to make the interface too large; you won't be able to navigate it.</p>
<p className="txt-left mb-3"><b>B. Audio settings</b></p>
          <p className="txt-left-g mb-3">Effects are accessible from Engine/Effects. They can be modified according to your creation (Reverb, delay, saturation, chorus, EQ, gain, etc.).</p>
          <p className="txt-left mb-3"><b>C. Logout</b></p>
          <p className="txt-left-g mb-3">
You can disconnect to free up a session or log in to another account from Settings/Account/Log out.</p>


        </div>
        <p className="title-left mt-12 mb-6">FAQ</p>
        <FAQ/>
      </div>

    </div>
  );
}
export default Learn;
