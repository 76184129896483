import { Link } from "react-router-dom";
import DownloadBlock from "../elements/download";

function Download() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoutez cette option pour un défilement fluide
    });
  };



  return (
    <div className="mx-auto max-w-[1100px]">
      <div className="pb-4 mt-10">
        <p className="title-left">Download Studio </p>
      </div>
      <div className=" w-full border-2 flex border-indigo-600 rounded-2xl p-3 pb-3">
        <div className="error-input">
          <div className="pl-0 float-left pt-1"></div>
          <div className="float-right">
            <p className="txt-left-g  float-right">
              <svg
                className="h-6 w-6 fill-current float-left mr-2 translate-y-0.5 text-indigo-600"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M12 2c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm1 12H11v-2h2v2zm0-3H11V7h2v4z" />
              </svg>
              Need a valid subscription to be used
              <Link
                onClick={scrollToTop}
                to="/pricing"
                className="txt-center-g-u desktop:block hidden float-right ml-1"
              >
                Buy now
              </Link>
            </p>
          </div>
        </div>
      </div>
      <DownloadBlock />
      <p className="title-left pt-4">Configuration </p>
      <div className="w-1/2 float-left pt-6">
        <div className="border-gray-800 border-2 h-16 border-r-0 rounded-l-2xl p-4">
          <p className="txt-left pt-1">
            <strong>Requiered </strong>
          </p>
        </div>
      </div>
      <div className="w-1/2 float-right pt-6 ">
        <div className="border-gray-800 border-2 h-16 border-l-1 rounded-r-2xl mb-6 p-4">
          <p className="txt-left pt-1">
            <strong>Recommended</strong>{" "}
          </p>
        </div>
      </div>
      <div className="w-1/2 float-left pt-6 ">
        <div className="border-gray-800 border-2 border-r-0 rounded-l-2xl mb-32 p-4">
          <ul className="btn-left">
            <li>
              <strong>Internet :</strong>
            </li>
            <li className="btn-left-g">Required standard connexion</li>
            <br></br>
            <li>
              <strong>Storage :</strong>
            </li>
            <li className="btn-left-g">6 GB</li>
            <br></br>
            <li>
              <strong>RAM :</strong>
            </li>
            <li className="btn-left-g">8 GB</li>
            <br></br>
            <li>
              <strong>Operating System :</strong>
            </li>
            <li className="btn-left-g">Windows8</li>
            <li className="btn-left-g">MacOS 10.0</li>
            <br></br>
            <li>
              <strong>Core :</strong>
            </li>
            <li className="btn-left-g"> 2.9 GHz Intel Core i5</li>
          </ul>
        </div>
      </div>
      <div className="w-1/2 float-right pt-0  ">
        <div className="border-gray-800 border-2 mb-32 border-l-1 rounded-r-2xl p-4">
          <ul className="btn-left">
            <li>
              <strong>Internet :</strong>
            </li>
            <li className="btn-left-g">Required standard connexion</li>
            <br></br>
            <li>
              <strong>Storage :</strong>
            </li>
            <li className="btn-left-g">6 GB</li>
            <br></br>
            <li>
              <strong>RAM :</strong>
            </li>
            <li className="btn-left-g">16GB</li>
            <br></br>
            <li>
              <strong>Operating System :</strong>
            </li>
            <li className="btn-left-g">Windows11</li>
            <li className="btn-left-g">MacOS 12.6.3</li>
            <br></br>
            <li>
              <strong>Core :</strong>
            </li>
            <li className="btn-left-g">2.9 GHz Intel Core i7</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Download;
