import React, { useState, useEffect } from 'react';
import LogoAbyss from '../medias/logoabyss.svg';
import LogIn from './login';
import Wallpaper from '../elements/wallpaper';

function Loader() {
  const [isLoading, setIsLoading] = useState(true);

 /* useEffect(() => {
    window.addEventListener('load', () => {
      setIsLoading(false);
    });

    return () => {
      window.removeEventListener('load', () => {});
    };
  }, []);*/

  return (
    <div>

    </div>
  );
}

export default Loader;

/*
    <div className={` ${isLoading ? 'opacity-100' : 'opacity-0'} transition-opacity duration-200 pointer-events-none`}>
        <div className='z-[55] fixed top-0 left-0'>
          <div className="flex justify-center items-center w-screen h-screen bg-gray-950">
            <img
              src={LogoAbyss}
              className="desktop:w-72 w-64 animate-bounce"
              alt="logoabyss"
            />
          </div>
        </div>
    </div>
  */