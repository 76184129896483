import Wallpaper from "../elements/wallpaper";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Error from "../elements/errormsg";
import SuccessMsg from "../elements/successmsg";
import { useEffect } from "react";

function ForgotPassword() {


  const [notifmsg, setnotifmsg] = useState("Message");
  const [notifmsg2, setnotifmsg2] = useState("Message");

  const [isDescended, setIsDescended] = useState(false);
  const [email, setEmail] = useState("");
   const [isDescended2, setIsDescended2] = useState(false);


  const resetemail = async () => {
    try {
      const response = await axios.post("/api:5FCR-wUS/password/sendemail", {
        email: email,
      });
  
      if (response.status == 200) {
        setnotifmsg("Check your email inbox");
        setIsDescended(true);
      } else {
        setnotifmsg2("Error");
        setIsDescended2(true);
      }
    } catch (error) {
      setnotifmsg2("Error");
      setIsDescended2(true);
    }
  };

  return (
    <div className="max-w-[500px] justify-center mt-16 ml-auto mr-auto">
            <SuccessMsg isDescended={isDescended} successmsg={notifmsg} />
      <Error isDescended2={isDescended2} successmsg={notifmsg2}/>
      <Wallpaper />
      <div className="">
        <div className="ml-4 mr-4">
          <div className="mb-4 ">
            <p className="title-center">Reset Password</p>
          </div>
          <div className="mb-8">
            <p className="txt-center-g">
              You can reset your password by entering your email address linked
              to your account below. A reset email will be sent to you.
            </p>
          </div>
          <div className="mb-24">
            <input className="input" onChange={(e) => setEmail(e.currentTarget.value)} type="email" placeholder="Email"></input>
          </div>
          <button       onClick={resetemail} className="btn-indigo txt-center">
            <p className="-translate-y-1">Send Reset Email</p>
          </button>
          <div className="mt-40 mb-16">
            <p className="btn-center-g">
              No account ?{" "}
              <Link
                to="/signup"
                className="txt-center-g-u"
              >
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;
