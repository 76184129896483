import React, { useState, useEffect } from "react";
import X from "../medias/Xwhite.svg";

const Error = (props) => {
  return (
    <div className={` fixed pointer-events-none 
    top-6 left-0 w-full duration-200 flex items-center justify-center z-[56] ${props.isDescended2 ? "translate-0" :" -translate-y-20"} }`}>

      <p className="bg-rose-200 px-4 duration-200 p-2 text-white rounded-2xl flex items-center">
        <img
          alt=""
          className="h-[23px] text-white mr-2"
          src={X}
        />
        {props.successmsg}
      </p>
    </div>
  );
};

export default Error;
