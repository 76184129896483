import Account from "../../elements/account";
import FAQ from "../faq";

function accounthelp() {
  return (
    <div>
      <Account />
      <div>
        <FAQ title="Help" />
      </div>
    </div>
  );
}
export default accounthelp;
