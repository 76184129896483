      import React, { useState, useEffect } from "react";
      import V from "../medias/Vwhite.svg";

      const SuccessMsg = (props) => {
        return (
          <div className={` fixed pointer-events-none top-6 left-0 w-full duration-200 flex items-center justify-center z-[56] ${props.isDescended ? "translate-0" :" -translate-y-20"} }`}>

            <p className="bg-indigo-600 px-4 duration-200 p-2 text-white rounded-2xl flex items-center">
              <img
                alt=""
                className="h-[23px] text-white mr-2"
                src={V}
              />
              {props.successmsg}
            </p>
          </div>
        );
      };

      export default SuccessMsg;
