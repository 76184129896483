import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Account from "../../elements/account";

function AccountSoundKits() {
  const token = localStorage.getItem("token");
  const [products, setProducts] = useState([]); // Utilisation correcte de useState


  const [url, setUrl] = useState(false);
  const [name, setname] = useState(false);










  const downloadFile = async () => {
    try {
      const response = await fetch(url);
      const data = await response.blob();
  
      // Crée un lien temporaire pour télécharger le fichier
      const url2 = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url2;
      link.setAttribute('download', name+'.zip');
      document.body.appendChild(link);
      link.click();
  
      // Libère les ressources
      window.URL.revokeObjectURL(url2);
    } catch (error) {
      console.error('Erreur lors du téléchargement du fichier :', error);
    }
  };
  
  // DOWNLOAD de STUDIO pour win
  
  const down = async (soundkit_id) => {
    try {
      const response = await axios.post(
        "/api:bUlrPTKD/soundkits/download",
        {
          id: soundkit_id
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token
          }
        }
      );
  
      if (response.status == 200) {
        setUrl(response.data.url);
        setname(response.data.name);
        downloadFile();
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };














  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await axios.get("/api:5FCR-wUS/purchased_soundkits", {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: token
          }
        });
  
        if (Array.isArray(response.data)) {
          setProducts(response.data);
        } else {
          console.error("Response data is not an array:", response.data);
        }
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }
    };
  
    fetchInvoice();
  }, [token]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Account />
      <p className="title-left mt-12 mb-6">Your soundkits</p>

      {products.length == 0 ? (
        <p className="txt-left-g italic my-10">No purchased soundkits</p>
      ) : (
      products.map((item) => (
      <div className="border-2 w-full border-gray-800 bg-gray-900 rounded-2xl mt-5 ">
       
          <div className="w-full b-b-2 inline-block m-4" key={item._soundkits[0].id}>
            <div className="h-full desktop:h-23 desktop:w-32 rounded-2xl float-left desktop:mr-4 mr-8 ">
              <img
                src={item._soundkits[0].cover_path.url}
                alt=""
                className="rounded-2xl "
              />
            </div>
            <div className="float-left pr-5">
              <p className="txt-left-g mt-10">
                <strong>{item._soundkits[0].name}</strong>
                <p className="txt-left-g">{item._soundkits[0].category} /  {item._soundkits[0].items_quantity}+ Items</p>
              </p>
            </div>
           
            <div className="float-right mt-12 mr-10">
            <Link className="btn-indigo" onClick={() => down(item._soundkits[0].id)}>
  <p className="txt-center">Download <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="inline-block ml-1 " viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
  </svg></p>
</Link>
            </div>
            <p className="txt-right-g float-right mt-14 mr-6 invisible desktop:visible">
                ZIP file.
              </p>
          </div>
          
       
      </div> )))}
      <div className="w-60 mt-6">
        <Link
          to="/soundkits"
          onClick={scrollToTop}
          className="border rounded-2xl border-gray-800 hover:bg-gray-800 bg-gray-900 p-2 text-center text-white"
        >
          Need kits ? Explore Store
        </Link>
      </div>
    </div>
  );
}
export default AccountSoundKits;
