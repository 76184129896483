import React, { useState, useEffect } from 'react';
import c1 from "../medias/c1.png";
import c2 from "../medias/c2.png";
import c3 from "../medias/c3.png";

const images = [c1, c2, c3];
const captions = ["Powerfull preset browser", "Easy to use midi file finder", "Custom your presets with samples & effects"]; // Ajoutez vos légendes ici

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentIndex]);

  return (
    <div className="relative w-full max-w-4xl mx-auto aspect-video">
      <div className="overflow-hidden rounded-lg">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <div key={index} className="w-full flex-shrink-0">
              <img src={image} alt={`Slide ${index + 1}`} className="w-full object-cover" />
            </div>
          ))}
        </div>
      </div>
      <button
        onClick={handlePrev}
        className="absolute top-1/2 left-2 rotate-180 transform -translate-y-1/2 p-2 bg-transparent opacity-50 -translate-x-10 text-white rounded-full focus:outline-none"
      >
        ➜
      </button>
      <button
        onClick={handleNext}
        className="absolute top-1/2 right-2 transform -translate-y-1/2 p-2 bg-transparent backdrop-blur-xl opacity-50 translate-x-10 text-white rounded-full focus:outline-none"
      >
        ➜
      </button>
      <div className='relative w-full mx-auto flex justify-center items-center'>
  
</div>
    </div>
  );
};

export default Carousel;
